import { Component, Input, OnInit } from "@angular/core";
import { Company } from "src/app/insurance-report/insurance-report.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-rank",
  templateUrl: "./rank.component.html",
  styleUrls: ["./rank.component.css"],
})
export class RankComponent implements OnInit {
  title = "托管车改善建议——高风险企业";
  // tslint:disable-next-line:member-ordering
  _data: {
    company_name: string;
    vehicle_count: number;
    score: number;
    pre_score: number;
    company_id: number;
    link: string;
  }[] = [];
  currentId: number;
  currentDate: Date = new Date();
  currentInsurance = "";
  enterprisNamePath = "";
  companyData: Company[];
  companyName = "";
  companyId: number;

  @Input()
  set data(
    data: {
      company_name: string;
      vehicle_count: number;
      score: number;
      pre_score: number;
      company_id: number;
      link: string;
    }[]
  ) {
    this._data = data;
    this._data.length = 10;
    this.companyData = data;

    if (data.length) {
      this.openInsuranceReport();
    }
  }
  get data(): {
    company_name: string;
    vehicle_count: number;
    score: number;
    pre_score: number;
    company_id: number;
    link: string;
  }[] {
    return this._data;
  }

  @Input() date: Date = new Date();

  constructor(private router: ActivatedRoute) {
    this._data.length = 10;
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.router.params.subscribe((r) => {
      this.currentId = r.id;
      this.currentInsurance = r.insurance;
      this.currentDate = r.date;
    });
  }

  openInsuranceReport() {
    const webUrl = environment.dataCenterWebUrl;

    this.data.forEach((item) => {
      // this.companyName = item.company_name;
      // this.companyId = item.company_id;

      item.link = `${webUrl}/report/vehicle/${this.currentId}/${this.currentInsurance}/${item.company_id}/${item.company_name}/${this.currentDate}`;
    });
  }
}
