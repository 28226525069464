import { Jsonify } from './jsonify.interface';

/**
 * 组织机构对象
 */
export class Organization implements Jsonify<Organization> {

  // 组织机构id
  data: string;

  // 组织机构名称
  label: string;

  // 下级部门对象数组
  children: Organization[] = [];

  // 页面显示用
  isSelected = false;

  parse(json: any): Organization {
    if (json['company_id']) {
      this.data = json['company_id'];
    } else if (json['id']) {
      this.data = json['id'];
    }
    this.label = json['name'];
    if (json['children']) {
      for (const obj of json['children']) {
        this.children.push(new Organization().parse(obj));
      }
    }
    return this;
  }

  toJsonObject(): any {
    return {
      company_id: this.data,
      name: this.label
    };
  }
}

/**
 * 权限对象
 */
export class Permission implements Jsonify<Permission> {
  data: string;
  code: string;
  label: string;
  name: string;
  isSelected = false;
  children: Permission[]  = [];

  parse(json: any): Permission {
    this.data = json['id'];
    this.code = json['code'];
    this.label = json['name'];
    if (json['authorityList']) {
      for (const obj of json['authorityList']) {
        this.children.push(new Permission().parse(obj));
      }
    }
    return this;
  }

  toJsonObject(): any {
    return {
      id : this.data,
      name: this.label,
      code: this.code
    };
  }
}
