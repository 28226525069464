import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  @Input() text =  '';

  @Input() showPop = false;

  @Output() hide = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
