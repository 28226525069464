import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import 'html2canvas';
declare const html2canvas: any;
declare const renderPDFPages: any;

@Component({
  selector: 'app-vehicle-safety-report',
  templateUrl: './vehicle-safety-report.component.html',
  styleUrls: ['./vehicle-safety-report.component.css']
})
export class VehicleSafetyReportComponent implements OnInit {

  imageDate: any[];
  loading: boolean;
  style: string;
  constructor() { }

  ngOnInit() {
  }

  // 导出pdf
  exportPdf() {
    this.imageDate = [];
    const boxlist = ['card1', 'card2'];
    const idList = ['img1', 'img2'];
    this.loading = true;
    this.transImage(idList);
  }

  transImage(idList) {
    const target = $(`#${idList[this.imageDate.length]}`);
    html2canvas(document.getElementById(`${idList[this.imageDate.length]}`)).then(
      (canvas) => {
        // 修改生成的宽度
        canvas.style.width = '1190px';
        canvas.style.height = '840px';
        const canvasImg = canvas.toDataURL('image/png');
        this.imageDate.push(canvasImg);
        if (idList.length === this.imageDate.length) {
          renderPDFPages(this.imageDate, '车队月报', 'a4', () => {
            this.loading = false;
          });
        } else {
          this.transImage(idList);
        }
      }
    );
  }

}
