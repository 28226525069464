import { BaseRequest } from './base.protocol';
import { environment } from '../../../environments/environment';

/**
 * 所有App的HTTP协议基类，各协议继承该类
 */
export abstract class AppBaseRequest extends BaseRequest {

  // 登录session，必须在登录后立刻设置，登出后设为null
  static session: string;

  constructor() {
    super();

    // 从ENV设置中获取基地址，使得dev和prod编译为不同的url地址
    this.baseUrl = environment.BASE_URL;
    // this.baseUrl = 'http://192.200.125.48:8080/'
  }

  protected makeExtraHeaders(): { [name: string]: string } {
    return {
      'session': AppBaseRequest.session
    };
  }
}

/**
 * 获取列表时的一些选项
 * 实际获取各不同列表时继承该接口
 */
export interface GetListOptions {

  /**
   * 查询分页大小 默认：10
   */
  pageSize?: number;

  /**
   * 所要查询的页码
   */
  page?: number;

  /**
   * 排序方式 desc-降序 asc-增序 默认：desc
   */
  direction?: string;
}

/**
 * 获得列表结果的一些通用内容
 * 实际获取各不同列表时继承该接口
 */
export interface GetListResult {

  /**
   * 结果总个数
   */
  resultCount: number;

  /**
   * 总页数
   */
  pageCount: number;

  /**
   * 页码
   */
  page: number;

  /**
   * 每页多少个结果
   */
  batch: number;

  /**
   * 排序方向
   */
  direction: string;
}
