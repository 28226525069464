import {SelectOption} from '../../shared/my-select/my-select.model';

export abstract class BasePage {

  // 显示提示信息框
  showDialog = false;

  // 错误信息
  errorMessage: string;

  // 显示确认框
  isConfirm = false;

  // 确认框提示文字
  confirmMessage: string;

  // 确认框回调函数
  confirmCallback: Function;

  // 当前登录的用户对象
  user: any;

  // 月份列表
  selectMonth: SelectOption[] = [];

  // 年份列表
  selectYear: SelectOption[] = [];

  // 当前时间
  now: Date = new Date();

  showErrorMessage(message: string) {
    this.errorMessage = message;
    this.showDialog = true;
  }

  showConfirm(message: string, callBack: Function) {
    this.confirmMessage = message;
    this.confirmCallback = callBack;
    this.isConfirm = true;
  }

  // 获取用户列表
  abstract fresh(isAdd?: boolean, type?: number, isTotalUpdate?: boolean, isListUpdate?: boolean);

  search() {
    this.fresh();
  }
}
