import { Component, OnInit, Input, } from '@angular/core';

@Component({
  selector: 'app-formulaBar',
  templateUrl: './formulaBar.component.html',
  styleUrls: ['./formulaBar.component.css']
})

export class FormulaBarComponent implements OnInit {

  @Input() public comment:string = '';
 
  ngOnInit() {
  }

}
