import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from '../../core/network/http.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../core/service/user.service';
import { UUID } from 'angular2-uuid';
import { ErrorService } from 'src/app/ErrorService/error.service';
import { StorageService } from 'src/app/core/storage/storage.service';
import { InsuranceUser } from 'src/app/core/model/user.model';

@Component({
  moduleId: module.id,
  selector: 'app-insurance-login',
  templateUrl: './insurance-login.component.html',
  styleUrls: ['./insurance-login.component.css']
})
export class InsuranceLoginComponent {


  constructor(protected router: Router,
    protected fb: FormBuilder,
    protected userService: UserService,
    private errorService: ErrorService,
    private storage: StorageService) {
    this.createLoginForm();
  }

  uuid: string;
  // 二维码的链接
  qrUrl: string;
  // 服务器的token
  token: string;
  // 显示提示信息框
  showDialog = false;
  // 错误信息
  errorMessage: string;
  // 计时器id
  intervalId: any;
  showError: string;
  // 显示那个Tab
  activeShow = 2;
  currentUser: InsuranceUser;

  validationMessages: any = {
    'account': {
      'required': '用户名不能为空'
    },
    'password': {
      'required': '密码不能为空'
    }
  };
  isSuccess = false;

  active = 1;

  // 登录form
  loginForm: FormGroup;
  currentError: string;

  showErrorMessage(message: string) {
    this.errorMessage = message;
    this.showDialog = true;
  }


  // 给表单内容做校验
  createLoginForm() {
    this.loginForm = this.fb.group({
      account: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
    this.loginForm.valueChanges
      .subscribe(data => this.onLoginValueChanged(data));
    this.onLoginValueChanged();
  }

  onLoginValueChanged(data?: any) {
    if (!this.loginForm) {
      return;
    }
    const form = this.loginForm;
    for (const field in this.validationMessages) {
      if (this.validationMessages[field] !== undefined) {
        this.currentError = null;
        const control = form.get(field);

        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          let foundError = false;
          for (const key in control.errors) {
            if (messages[key]) {
              this.currentError = messages[key];
              foundError = true;
              break;
            }
          }
          if (foundError) {
            break;
          }
        }
      }
    }
  }

  // 切换button生成新的uuid
  gotoLogin(data) {
    this.clearTimer();
    this.active = data;
    if (data === 2) {
      // 点击切换tab
      this.uuid = UUID.UUID();
      this.qrUrl = environment.salesmanWxLoginUrl + '/' + this.uuid;
      this.requestServiceGetJwt();
    }
  }
  // 扫码登陆
  requestServiceGetJwt() {
    this.clearTimer();
    this.intervalId = setInterval(() => {
      this.userService.getInsuranceWxLoginJwt(this.uuid).subscribe(jwt => {
        if (jwt) {
          this.clearTimer();
          this.getUserInfo(jwt);
        }
      }, error => {
        console.log(this.errorService.getErrorText(error));
      });
    }, 5000);
  }

  // 检查是否根据uuid 生成机构平台jwt
  isCheckQrcodeLogin(jwt) {
    if (jwt) { return this.clearTimer(); }
    this.qrUrl = environment.salesmanWxLoginUrl + '/' + this.uuid;

    this.intervalId = setInterval(() => {
      HttpService.skipMethod = true;
      this.userService.getInsuranceWxLoginJwt(this.uuid).subscribe((r) => {
        HttpService.skipMethod = false;
        this.clearTimer();
        setTimeout(() => {
          if (jwt) {
            const url = environment.salesmanWxLoginUrl + '/' + this.uuid;
            location.replace(url);
          } else {
            console.log(`login no result`);
          }
        }, 3000);
      }, error => {
        HttpService.skipMethod = false;
        if (error === 'not_login') {
          return;
        } else {
          this.showErrorMessage(this.errorService.getErrorText(error));
        }
      });
    }, 3000);

  }

  // 清空计时器
  clearTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }


  // 保险公司用户登陆
  login() {
    const loginValue = this.loginForm.value;
    const username = loginValue.account;
    const password = loginValue.password;
    if (!username) {
      // 用户不存在
      this.showErrorMessage('请输入用户名');
      return false;
    } else {
      if (!password) {
        // 密码错误
        this.showErrorMessage('请输入密码');
        return false;
      }
      if (!/^[A-Za-z0-9]+$/.test(username)) {
        this.showErrorMessage('用户名应当为字母或数字');
        return false;
      }
    }
    this.userService.platformUserloginNew(username, password)
      .subscribe(jwt => {
        this.userService._currentUser = undefined;
        this.getUserInfo(jwt);
        this.storage.saveValue('k&v', { k: username, v: password });
      }, e => {
        if (e.status === 400) {
          if (e.error.errcode === 'login_name_or_password_error') {
            this.showErrorMessage('用户名或密码错误');
          }
        }
      });
  }

  // 根据JWT获取用户信息
  getUserInfo(jwt: string) {
    this.userService.getUserInfo(jwt).subscribe(user => {
      this.checkToRouter(user);
    }, e => {
      console.log('**getUserInfo *e***', e);
      // this.showErrorMessage(e.error ? e.error.errorMsg : '获取用户信息失败');
    });
  }


  checkToRouter(user: InsuranceUser) {
    console.log(user);
    let url = '';
    if (user) {
      // tslint:disable-next-line:max-line-length
      if (user.insurance_role_list.length === 0) {
        const list = [];
        user.insurance_role_list.forEach(r => {
          r.insurance_authority_list.forEach(auth => {
            list.push(auth);
          });
        });
        if (!list.length) {
          url = 'empty';
        }
      } else {
        // 检查之前是否缓存有跳转链接，如果有，跳转到缓存的url
        if (this.userService.redirectUrl) {
          url = this.userService.redirectUrl;
        } else {
          url = 'index';
        }
      }
      this.router.navigate([url]).then(r => {
        console.log(`login and navigate to:${r}`);
        // 跳转完后清空缓存的跳转url
        this.userService.redirectUrl = null;
      }, reason => {
        console.log(`navigate fail:${reason}`);
      });
    } else {
      console.log(`login no result`);
    }
  }
}
