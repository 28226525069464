/**
 * 搜索框
 */
import { GpsTrajectoryParams, Vehicle, VehicleSimplejson } from './../../core/vehicle-trajectory.model';
import { Observable, Subscription } from 'rxjs';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { delay, filter } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { VehicleTrajectoryService } from '../../core/vehicle-trajectory.service';
import { UserService } from '../../../core/service/user.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
  searchGpsSub: Subscription;
  errorMessage: string;
  // 搜索图标
  loading: boolean;
  // 搜索车牌
  searchLicense = new FormControl('');

  options: Vehicle[] = [];
  startDate: Date = new Date();
  endDate: Date = new Date();
  startHour: number;
  endHour: number;
  startMinute: number;
  endMinute: number;
  @Input() ifCenter = true;
  _currentVehicle: Vehicle = new Vehicle();
  @Input()
  set currentVehicle(option: Vehicle) {
    this._currentVehicle = option ? option : new Vehicle();
  }
  get currentVehicle(): Vehicle {
    return this._currentVehicle;
  }
  @Input()
  set startTime(time: Date) {
    this.startDate = time;
    this.startHour = time.getHours();
    this.startMinute = time.getMinutes();
  }
  @Input()
  set endTime(time: Date) {
    this.endDate = time;
    this.endHour = time.getHours();
    this.endMinute = time.getMinutes();
  }
  // tslint:disable-next-line:no-output-rename
  @Output('searchGPS') searchGPS: EventEmitter<{ option, vehicle }> = new EventEmitter<{ option, vehicle }>();

  constructor(private vehicleTrajectoryService: VehicleTrajectoryService, private userService: UserService) {
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.startHour = this.startDate.getHours();
    this.startMinute = this.startDate.getMinutes();
    this.endHour = this.endDate.getHours();
    this.endMinute = this.endDate.getMinutes();
  }

  ngOnInit() {
    this.searchLicense.valueChanges
      .pipe(
        filter(r => {
          if (r < 4) {
            this.currentVehicle.licenseCard = r;
            this.currentVehicle.vehicleId = undefined;
            this.options = [];
          }
          if (r !== this.currentVehicle.licenseCard) {
            this.currentVehicle.vehicleId = undefined;
          }
          return r !== this.currentVehicle.licenseCard && r.length >= 4;
        }),
        delay(500)
      )
      .subscribe(r => {
        this._currentVehicle.vehicleId = undefined;
        this.hideError();
        if (this.searchGpsSub) {
          this.searchGpsSub.unsubscribe();
        }
        this.loading = true;
        this.searchGpsSub = this.vehicleTrajectoryService.getVehicleNew(this.userService._currentUser.org_id, r)
          .subscribe(list => {
            this.options = list;
            this.loading = false;
            if (list.length === 0) {
              this.showError('未找到该车牌');
            } else {
              this.errorMessage = '';
            }
          },
            e => {
              this.loading = false;
              this.showError('查询失败');
            }
          );
      });
  }

  searchGPSPoints() {
    this.options = [];
    this.startDate.setHours(this.startHour, this.startMinute, 0, 0);
    this.endDate.setHours(this.endHour, this.endMinute, 0, 0);
    if (this.endDate.valueOf() - this.startDate.valueOf() > 86400000) {
      this.showError('查询的时间不能超过24小时');
      return false;
    } else if (this.startDate.valueOf() - this.endDate.valueOf() > 0) {
      this.showError('请输入合理的时间段');
      return false;
    }
    const option: GpsTrajectoryParams = {
      vehicleId: this.currentVehicle.vehicleId,
      startTime: this.startDate,
      endTime: this.endDate
    };
    this.searchGPS.emit({ option: option, vehicle: this.currentVehicle });
  }
  setCurrentVehicle(vehicle: Vehicle) {
    this._currentVehicle.licenseCard = vehicle.licenseCard;
    this._currentVehicle.licenseColor = vehicle.licenseColor;
    this._currentVehicle.vehicleId = vehicle.vehicleId;
    this.searchLicense.setValue(vehicle.licenseCard);
    this.options = [];
  }
  showError(message: string) {
    this.errorMessage = message;
  }
  hideError() {
    this.errorMessage = '';
  }
}
