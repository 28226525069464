import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserService, } from '../../core/service/user.service';
import { ErrorCode } from '../../core/model/errorCode.model';
import { InsuranceOrgListSimple } from 'src/app/bind/bind.serevice';
import { AuthService } from 'src/app/core/service/auth/auth.service';
import { InsuranceUser } from 'src/app/core/model/user.model';
import { PermissionService } from '../permissions/permission.service';
import { StorageService } from 'src/app/core/storage/storage.service';
import { switchMap } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // 是否显示分公司列表
  isHasChildrenList: Boolean;
  orgInsurceName: string;
  isMoreData = 0;
  @Input() reverse = false;
  @Output() showPop = new EventEmitter();

  ifShowUser = false;

  showChangePassword = false;

  oldPassword: string;

  newPassword: string;

  confirmPassword: string;

  userId: number;

  errorMessage: string;

  companyList: InsuranceOrgListSimple[] = [];

  selectedCompany: InsuranceOrgListSimple;

  placeholder = '';

  user: InsuranceUser;

  weChatLoginUser = false;

  moreDataArr: string[] = ['/main/analysis-report', '/main/safety', '/main/rank', '/main/record'];
  // 全部权限
  authList: string[] = [];
  @HostListener('window:click')
  onClick() {
    this.ifShowUser = false;
  }

  logOut() {
    this.userService.logout();
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private storageService: StorageService) {
  }

  changePassword() {
    const rule = /^[a-zA-Z0-9]{6,16}$/;
    if (!rule.test(this.newPassword)) {
      this.errorMessage = '密码应为6到16位字母数字';
      return;
    }
    if (this.newPassword !== this.confirmPassword) {
      this.errorMessage = '密码不一致';
      return false;
    }
    if (this.newPassword && this.oldPassword) {
      this.userId = this.authService.getUserId();
      this.userService.editPlatformPasswordNew(this.userId, this.oldPassword, this.newPassword)
        .subscribe(val => {
          this.storageService.getValue<{ k: string, v: string }>('k&v')
            .pipe(
              switchMap(r => {
                r.v = this.newPassword;
                return this.storageService.saveValue('k&v', r);
              })
            )
            .subscribe(
              r => {
                this.cancel();
              }
            );
        }, error => {
          this.errorMessage = error.error.errmsg ? error.error.errmsg : error.error.errcode;
        });
    } else {
      this.errorMessage = '密码不能为空';
    }
  }

  ngOnInit() {
    if (this.authService.getUserType()) {
      this.weChatLoginUser = true;
    } else {
      this.weChatLoginUser = false;
    }
    const getAuthTree = (auth) => {
      auth.forEach(r => {
        if (this.authList.indexOf(r.code) < 0) {
          this.authList.push(
            r.code
          );
        }
        if (r.children_list && r.children_list.length > 0) {
          getAuthTree(r.children_list);
        }
      });
    };
    this.userService.getUserInfo(this.authService.getLocalJWT()).subscribe(user => {
      this.user = user;
      if (user.insurance_role_list && user.insurance_role_list.length > 0) {
        user.insurance_role_list.forEach(role => {
          if (role.insurance_authority_list) {
            getAuthTree(role.insurance_authority_list);
          }
        });
      }
      // 有总公司权限
      if (this.authList.indexOf('b_headquarters') >= 0) {
        this.isHasChildrenList = true;
        this.companyList = this.user.insurance_org.children_list;
        this.selectedCompany = this.companyList.find(e => e.id === user.org_id);
        if (!this.selectedCompany) {
          this.selectedCompany = this.user.insurance_org.children_list[0];
        }
        this.placeholder = this.selectedCompany.name;
      } else {
        this.isHasChildrenList = true;
        this.orgInsurceName = this.user.insurance_org.name;
      }
    });

    const url = location.pathname;
    for (const i of this.moreDataArr) {
      if (url.indexOf(i) >= 0) {
        this.isMoreData = 2;
        break;
      } else {
        if (url.indexOf('index') >= 0) {
          this.isMoreData = 0;
        } else if (url.indexOf('adas') >= 0) {
          this.isMoreData = 3;
        } else {
          this.isMoreData = 1;
        }
      }
    }

    this.router.events.subscribe(
      r => {
        if (r instanceof NavigationEnd) {
          for (const i of this.moreDataArr) {
            if (r.url.indexOf(i) >= 0) {
              this.isMoreData = 2;
              break;
            } else {
              if (r.url.indexOf('index') >= 0) {
                this.isMoreData = 0;
              } else if (r.url.indexOf('adas') >= 0) {
                this.isMoreData = 3;
              } else {
                this.isMoreData = 1;
              }
            }
          }
        }
      }
    );
  }


  chooseCompany(value) {

    this.userService._currentUser.org_id = value.id;

    this.userService._currentUser.insurance_org.name = value.name;

    this.storageService.saveValue(this.userService.saveInsuranceUser, this.userService.currentUser)
      .subscribe(r => {
        window.history.go();
      });
  }


  // 是否有权限
  isHasAuthrity(code: string): boolean {
    return this.authList.indexOf(code) !== -1;
  }

  cancel() {
    this.showChangePassword = false;
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
  }
}
