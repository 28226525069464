import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, NavigationExtras } from '@angular/router';
import { AuthService } from './auth.service';
import { PermissionService } from 'src/app/shared/permissions/permission.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authService: AuthService,
    private permission: PermissionService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url = state.url;
    return this.checkLogin(url, state.url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean| Observable<boolean> {
    return this.permission.canInit(state.url);
  }

  private checkLogin(redirectUrl: string, currentRoute: string): boolean {
    const loginExp = /\/app-insurance-login*/;
    if (loginExp.test(currentRoute)) {
      if (this.authService.isLoggedIn()) {
        this.router.navigate(['/empty']);
        return false;
      } else {
        return true;
      }
    } else {
      if (this.authService.isLoggedIn()) {
        return true;
      } else {
        const navigationExtras: NavigationExtras = {
          queryParams: { 'redirect': redirectUrl }
        };
        this.router.navigate(['/app-insurance-login'], navigationExtras);
        return false;
      }
    }
  }
}
