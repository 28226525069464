import { Component, Input, OnInit } from '@angular/core';
export class WxBindingData {
  should_bind_colonel_count: number;
  should_bind_captain_count: number;
  bind_colonel_count: number;
  bind_captain_count: number;
  colonel_bind_rate: number;
  captain_bind_rate: number;
}
@Component({
  selector: 'app-wx-binding',
  templateUrl: './wx-binding.component.html',
  styleUrls: ['./wx-binding.component.css']
})
export class WxBindingComponent implements OnInit {
  title = '公众号绑定情况';
  _data: WxBindingData = new WxBindingData();
  @Input()
  set data(data: WxBindingData) {
    this._data = data;
    if (data) {
      this.setRemark();
    }
  }
  get data(): WxBindingData {
    return this._data;
  }
  remark = '';
  constructor() { }
  ngOnInit() {
  }
  setRemark() {
    if (this.data.captain_bind_rate >= 100) {
      this.remark = '保证公众号内风控服务功能落地到位，切实保证驾驶安全。';
    } else {
      if (this.data.captain_bind_rate < 60) {
        this.remark = '机构公众号绑定率较低，机构需增加内部及企业客户的推广力度；绑定率目标60%。';
      } else if (this.data.captain_bind_rate >= 60) {
        this.remark = '保证公众号绑定率，风控服务功能落地到位。';
      }
    }

  }
}
