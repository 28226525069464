import { Component, ElementRef, HostListener, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import * as echarts from 'echarts';
export class OverSpeedTimesData {
  hundred_km_overspeed_count_avg: number[] = [];
  pre_hundred_km_overspeed_count_avg: number[] = [];
  country_hundred_km_overspeed_count_avg: number[] = [];
}
@Component({
  selector: 'app-over-speed-times',
  templateUrl: './over-speed-times.component.html',
  styleUrls: ['./over-speed-times.component.css']
})
export class OverSpeedTimesComponent implements OnInit, AfterViewInit {
  title = '百公里超速次数';
  @ViewChild('chart', { static: true }) _chart: ElementRef;
  chart: echarts.ECharts;
  remark = '';
  onResize() {
    if (this.chart) { this.chart.resize(); }
  }
  // tslint:disable-next-line:member-ordering
  @Input() date = new Date();
  // tslint:disable-next-line:member-ordering
  _data: OverSpeedTimesData = new OverSpeedTimesData;
  @Input()
  set data(data: OverSpeedTimesData) {
    this._data = data;
    if (data) {
      this.setChart(data);
      this.setRemark(data);
    }
  }
  get data(): OverSpeedTimesData {
    return this._data;
  }
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.chart = echarts.init(this._chart.nativeElement);
  }
  setRemark(data: OverSpeedTimesData) {
    const month = this.date.getMonth();
    const currentValue = data.hundred_km_overspeed_count_avg[month] || 0;
    const lastValue = data.pre_hundred_km_overspeed_count_avg[month] || 0;
    const growRate = lastValue ? Math.round((currentValue - lastValue) / lastValue * 1000) / 10 : 0;
    this.remark = `托管车辆百公里超速次数${currentValue}`;
    const value = data.country_hundred_km_overspeed_count_avg[month];
    this.remark += value && value !== currentValue ?
      `，${value < currentValue ? '高于' : '低于'}全国均值${value}` : '';
    this.remark += growRate ? `，与${this.date.getFullYear() - 1}年同期相比${growRate > 0 ? '上升' : '下降'}${Math.abs(growRate)}%。` : '';
  }
  setChart(data: OverSpeedTimesData) {
    this.chart.resize();
    const option = {
      legend: {
        data: [`${this.date.getFullYear() - 1}年托管车辆百公里超速次数`, `${this.date.getFullYear()}年托管车辆百公里超速次数`]
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '15px',
        right: '15px',
        bottom: '15px',
        top: '40px',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
        }
      ],
      series: [
        {
          name: `${this.date.getFullYear() - 1}年托管车辆百公里超速次数`,
          type: 'line',
          symbol: 'circle',
          itemStyle: {
            color: '#32C5FF'
          },
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.pre_hundred_km_overspeed_count_avg
        },
        {
          name: `${this.date.getFullYear()}年托管车辆百公里超速次数`,
          type: 'line',
          symbol: 'circle',
          itemStyle: {
            color: '#B620E0'
          },
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.hundred_km_overspeed_count_avg
        }
      ]
    };
    this.chart.setOption(option);
    this.chart.resize();
  }
}

