import { Component, ElementRef, HostListener, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import * as echarts from 'echarts';
import * as moment from 'moment';
export class MilesTimelengthData {
  driving_km_avg: number[] = [];
  driving_hour_avg: number[] = [];
  pre_driving_km_avg: number[] = [];
  pre_driving_hour_avg: number[] = [];
}
@Component({
  selector: 'app-miles-and-timelength',
  templateUrl: './miles-and-timelength.component.html',
  styleUrls: ['./miles-and-timelength.component.css']
})
export class MilesAndTimelengthComponent implements OnInit, AfterViewInit {
  title = '车均行驶里程、行驶时长';
  remark = '';
  @Input() insurance = '';
  @ViewChild('chart', { static: true }) _chart: ElementRef;
  chart: echarts.ECharts;
  onResize() {
    if (this.chart) { this.chart.resize(); }
  }
  // tslint:disable-next-line:member-ordering
  @Input() date = new Date();
  // tslint:disable-next-line:member-ordering
  _data: MilesTimelengthData = new MilesTimelengthData;
  @Input()
  set data(data: MilesTimelengthData) {
    this._data = data;
    if (data) {
      this.setRemark(data);
      this.setChart(data);
    }
  }
  get data(): MilesTimelengthData {
    return this._data;
  }
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.chart = echarts.init(this._chart.nativeElement);
  }
  setRemark(data: MilesTimelengthData) {
    const month = this.date.getMonth();
    const currentMile = data.driving_km_avg[month] || 0;
    const currentHour = data.driving_hour_avg[month] || 0;
    let lastMile = 0;
    let lastHour = 0;
    if (month === 0) {
      lastMile = data.pre_driving_km_avg[11] || 0;
      lastHour = data.pre_driving_hour_avg[11] || 0;
    } else {
      lastMile = data.pre_driving_km_avg[month] || 0;
      lastHour = data.pre_driving_hour_avg[month] || 0;
    }
    const mileRate = Math.round((currentMile - lastMile) / lastMile * 1000) / 10;
    const hourRate = Math.round((currentHour - lastHour) / lastHour * 1000) / 10;
    // tslint:disable-next-line:max-line-length
    this.remark = `${this.insurance}${moment(this.date).format('YYYY年M月')}托管车辆车均行驶里程${currentMile}公里`;
    // tslint:disable-next-line:max-line-length
    this.remark += mileRate && lastMile ? `，同比${mileRate >= 0 ? '上升' : '下降'}${Math.abs(mileRate)}%；车均行驶时长${currentHour}个小时` : `；车均行驶时长${currentHour}个小时`;
    this.remark += hourRate && lastHour ? `，同比${hourRate >= 0 ? '上升' : '下降'}${Math.abs(hourRate)}%。` : '。';
  }
  setChart(data: MilesTimelengthData) {
    this.chart.resize();
    const option = {
      legend: {
        data: [`${this.date.getFullYear() - 1}年托管车均行驶里程(公里)`, `${this.date.getFullYear()}年托管车均行驶里程(公里)`,
        `${this.date.getFullYear() - 1}年托管车均行驶时长(小时)`, `${this.date.getFullYear()}年托管车均行驶时长(小时)`]
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '15px',
        right: '15px',
        bottom: '15px',
        top: '40px',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '单位(公里)',
          axisLabel: {
            formatter: '{value}'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
        },
        {
          type: 'value',
          name: '单位(小时)',
          min: 0,
          axisLabel: {
            formatter: '{value}'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          splitNumber: 5,
        }
      ],
      series: [
        {
          name: `${this.date.getFullYear() - 1}年托管车均行驶里程(公里)`,
          type: 'bar',
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#27BBFF' // 0% 处的颜色
              }, {
                offset: 0.5, color: '#6361FF' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.pre_driving_km_avg
        },
        {
          name: `${this.date.getFullYear()}年托管车均行驶里程(公里)`,
          type: 'bar',
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#FFA327' // 0% 处的颜色
              }, {
                offset: 0.5, color: '#FF4D28' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.driving_km_avg
        },
        {
          name: `${this.date.getFullYear() - 1}年托管车均行驶时长(小时)`,
          type: 'line',
          symbol: 'circle',
          itemStyle: {
            color: '#32C5FF'
          },
          yAxisIndex: 1,
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.pre_driving_hour_avg
        },
        {
          name: `${this.date.getFullYear()}年托管车均行驶时长(小时)`,
          type: 'line',
          symbol: 'circle',
          itemStyle: {
            color: '#B620E0'
          },
          yAxisIndex: 1,
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.driving_hour_avg
        }
      ]
    };
    this.chart.setOption(option);
  }
}
