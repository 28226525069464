/**
 * Created by AGUANG on 2017/5/12.
 */
import { Directive, ElementRef, HostListener, OnInit, Output, EventEmitter} from '@angular/core';

@Directive({
  selector: '[telephoneValidator]'
})
export class TelephoneValidatorDirective implements OnInit {

  constructor() {}

  @Output() showErrorMessage: EventEmitter<any> = new EventEmitter();

  value: string;

  @HostListener('blur', ['$event'])

  onBlur(event: any) {
    if (event.target.value) {
      if (!(/^1[34578]\d{9}$/.test(event.target.value)) && !(/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(event.target.value))) {
        this.showErrorMessage.emit('联系方式格式可能不符合规范');
      }
    }
  }

  ngOnInit() {
  }
}
