import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, HostListener, Input } from '@angular/core';
import * as echarts from 'echarts';
export class VehicleOnlineData {
  currnetNumber: number[] = [];
  lastNumber: number[] = [];
  currnetRate: number[] = [];
  lastRate: number[] = [];
}
@Component({
  selector: 'app-vehicle-online',
  templateUrl: './vehicle-online.component.html',
  styleUrls: ['./vehicle-online.component.css']
})
export class VehicleOnlineComponent implements OnInit, AfterViewInit {
  title = '车辆上线数据';
  currnetNumber = 0;
  currnetRate = 0;
  @ViewChild('chart', { static: true }) _chart: ElementRef;
  chart: echarts.ECharts;
  @Input() date = new Date();
  @Input() insurance = '';
  @HostListener('window:resize'/*, ['$event']*/)
  onResize() {
    // console.log('vehicle-online')
    if (this.chart) {
      // console.log('vehicle-online---onResize')
      this.chart.resize();
    }
  }
  // tslint:disable-next-line:member-ordering
  _data: VehicleOnlineData = new VehicleOnlineData;
  @Input()
  set data(data: VehicleOnlineData) {
    this._data = data;
    if (data) {
      this.currnetNumber = data.currnetNumber[this.date.getMonth()] || 0;
      this.currnetRate = data.currnetRate[this.date.getMonth()] || 0;
      this.setChart(data);
    }
  }
  get data(): VehicleOnlineData {
    return this._data;
  }
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.chart = echarts.init(this._chart.nativeElement);
  }
  setChart(data: VehicleOnlineData) {
    // this.chart.resize();
    const option = {
      legend: {
        data: [`${this.date.getFullYear() - 1}年托管车辆数`, `${this.date.getFullYear()}年托管车辆数`,
        `${this.date.getFullYear() - 1}年托管车辆上线率`, `${this.date.getFullYear()}年托管车辆上线率`]
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '15px',
        right: '15px',
        bottom: '15px',
        top: '40px',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '单位(辆)',
          axisLabel: {
            formatter: '{value}'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
        },
        {
          type: 'value',
          name: '上线率(%)',
          min: 0,
          max: 100,
          interval: 10,
          axisLabel: {
            formatter: '{value} %'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          splitNumber: 5,
        }
      ],
      series: [
        {
          name: `${this.date.getFullYear() - 1}年托管车辆数`,
          type: 'bar',
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#27BBFF' // 0% 处的颜色
              }, {
                offset: 0.5, color: '#6361FF' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.lastNumber
        },
        {
          name: `${this.date.getFullYear()}年托管车辆数`,
          type: 'bar',
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#FFA327' // 0% 处的颜色
              }, {
                offset: 0.5, color: '#FF4D28' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.currnetNumber
        },
        {
          name: `${this.date.getFullYear() - 1}年托管车辆上线率`,
          type: 'line',
          symbol: 'circle',
          itemStyle: {
            color: '#32C5FF'
          },
          yAxisIndex: 1,
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.lastRate
        },
        {
          name: `${this.date.getFullYear()}年托管车辆上线率`,
          type: 'line',
          symbol: 'circle',
          itemStyle: {
            color: '#B620E0'
          },
          yAxisIndex: 1,
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.currnetRate
        }
      ]
    };
    this.chart = echarts.init(this._chart.nativeElement);
    this.chart.setOption(option);
    this.chart.resize();
  }
}
