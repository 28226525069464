import { LevelInsuranceReportService } from './../../level_insurance-report.service';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-projection',
  templateUrl: './projection.component.html',
  styleUrls: ['./projection.component.css']
})
export class ProjectionComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() logo: string;
  @ViewChild('content', { static: false }) content: ElementRef<HTMLDivElement>;
  @Input() height: string;
  @Output() resize = new EventEmitter<any>();
  @HostListener('window:resize'/*, ['$event']*/)
  onResize() {
    this.setHeight();
  }
  constructor(
    private service: LevelInsuranceReportService
  ) {
    service.ChangeSize.subscribe(r => {
      if (r) {
        this.content.nativeElement.style.height = '896px';
      }
      setTimeout(() => {
        this.resize.emit('');
      }, 0);
    });
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.setHeight();
  }
  setHeight() {
    if (this.height) {
      this.content.nativeElement.style.height = this.height;
    } else {
      this.content.nativeElement.style.height = this.content.nativeElement.offsetWidth * 840 / 1200 + 'px';
    }
    setTimeout(() => {
      this.resize.emit('');
    }, 0);
    // console.log(this.content.nativeElement.offsetWidth);
  }
}
