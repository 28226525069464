import { Injectable } from '@angular/core';
import { Jsonify } from '../model/jsonify.interface';
import { Observable, from } from 'rxjs';
import * as localforage from 'localforage';

/**
 * 获取和存储对象到本地
 */
@Injectable()
export class StorageService {

  constructor() { }

  /**
   * 保存一个值到本地
   */
  saveValue<T>(key: string, value: T): Observable<T> {
    return from(localforage.setItem(key, value));
  }

  /**
   * 从本地获取一个值
   */
  getValue<T>(key: string): Observable<T> {
    return from(localforage.getItem<T>(key));
  }

  /**
   * 从本地删除一个值
   */
  removeValue<T>(key: string): Observable<void> {
    return from(localforage.removeItem(key));
  }

  /**
   * 保存对象到本地
   */
  saveItem<T extends Jsonify<T>>(key: string, value: Jsonify<T>): Observable<Jsonify<T>> {
    return from((localforage.setItem(key, value.toJsonObject())));
  }

  /**
   * 从本地取出对象的JSON字符串形式
   */
  getJson(key: string): Observable<string> {
    return from(localforage.getItem<string>(key));
  }

  /**
   * 从本地删除对象
   */
  deleteItem(key: string): Observable<void> {
    return from(localforage.removeItem(key));
  }
}
