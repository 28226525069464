import { environment } from './../../environments/environment';
import { AdasService, CaptainDailyParm, CaptainDailyReport } from './adas.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MyPaginatorComponent } from '../shared/myPaginator/paginator.component';
import { UserService } from '../core/service/user.service';
import { StorageService } from '../core/storage/storage.service';
import { InsuranceUser } from '../core/model/user.model';
import { observable } from 'rxjs';
import { AuthService } from '../core/service/auth/auth.service';
@Component({
  selector: 'app-adas-porject',
  templateUrl: './adas-porject.component.html',
  styleUrls: ['./adas-porject.component.css']
})

export class AdasPorjectComponent implements OnInit {

  @ViewChild('paginator1', { static: true }) paginator1: MyPaginatorComponent;
  @ViewChild('myIframe', { static: false }) myIframe: HTMLIFrameElement;

  orgId: number;
  IfshowReport: boolean;
  currentUser: InsuranceUser;
  // 查询参数
  companyName: string;
  captainName: string;
  bindState = null;
  pageNumber = 0;
  readonly CaptainLimit = 20;
  showChangePassword = false;
  // 当前车队长的搜索配置
  curentCaptainSearchConfig: CaptainDailyParm;

  /**
   * 车队当前页码，从0开始
   */
  captainCurrentPage = 0;
  /**
   * 显示错误信息
   */
  message: string;
  ifShowE: boolean;

  // 当天日期
  date: Date = new Date();

  /**
   * 当前页显示的车队长列表
   */
  // showCaptainInfos: ShowCaptainInfo[];
  showCaptainInfos: CaptainDailyReport[] = [];

  captainsTotalCount: number;

  // 绑定状态列表
  statusList = [
    { value: '', name: '全部状态' },
    { value: '1', name: '已绑定' },
    { value: '0', name: '未绑定' }
  ];

  currentStatusName: string;
  currentStatus: string;

  constructor(
    private adasService: AdasService,
    private userService: UserService,
    private storageService: StorageService,
    private authService: AuthService
  ) {

    if (this.date.getHours() < 10) {
      this.date.setDate(this.date.getDate() - 2);
    } else {
      this.date.setDate(this.date.getDate() - 1);
    }
  }

  // 初始化
  ngOnInit() {
    this.userService.getUserInfo(this.authService.jwt).subscribe(r => {
      this.orgId = r.org_id;
      this.getCaptainsListInfo(0);
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {

  }

  changePage(page: number) {
    console.log(page);
    const box = document.getElementById('box');
    if (box) {
      box.scrollTop = 0;
    }
    this.getCaptainsListInfo(page);
  }

  getCaptainsListInfo(page?: number) {
    const option: CaptainDailyParm = {
      bind_status: this.bindState,
      captain_name: this.captainName,
      company_name: this.companyName,
      page_number: page + 1,
      page_size: this.CaptainLimit,
    };
    this.adasService.getCaptainsNew(this.orgId, option)
      .subscribe(result => {
        this.showCaptainInfos = result.list;
        this.captainsTotalCount = result.total;
        this.pageNumber = page;
        this.IfshowReport = false;
        this.paginator1.setPage(this.captainsTotalCount, this.pageNumber * this.CaptainLimit, this.CaptainLimit);
      }, error => {
        this.showMessage(error.statusText);
      });
  }
  /**
   * 显示日报
   */
  showReport(option: CaptainDailyReport) {
    const currentStatus = option.selected;
    this.showCaptainInfos.forEach(e => {
      e.selected = false;
    });
    if (currentStatus) {
      this.IfshowReport = false;
      option.selected = false;
    } else {
      this.IfshowReport = true;
      option.selected = true;
    }
    let dateNumber: number;
    const TDate = new Date();
    if (TDate) {
      if (TDate.getHours() < 10) {
        TDate.setDate(TDate.getDate() - 2);
        TDate.setHours(0, 0, 0, 0);
        dateNumber = TDate.valueOf();
      } else {
        TDate.setDate(TDate.getDate() - 1);
        TDate.setHours(0, 0, 0, 0);
        dateNumber = TDate.valueOf();
      }
    }

    const iframe = document.createElement('iframe');
    if (!iframe) { return; }
    iframe.setAttribute('class', 'myIframe');
    iframe.setAttribute('name', 'daily-report-iframe');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('id', 'myIframe');
    iframe.setAttribute('style', 'flex:1');
    const box = document.getElementById('iframeBox');
    const currentIframe = document.getElementById('myIframe');
    if (currentIframe) {
      box.removeChild(currentIframe);
      box.appendChild(iframe);
    } else {
      box.appendChild(iframe);
    }
    const url = `${environment.wxCaptainUrl}reports/no-auth-vehicles?captainId=${option.captain_id}`;
    window.open(url, `daily-report-iframe`);
  }
  /**
   * 显示/隐藏提示
   */
  showMessage(message) {
    this.ifShowE = true;
    this.message = message;
  }
  hideMessage() {
    this.ifShowE = false;
    this.message = '';
  }
  // 改变绑定状态
  setStatus(value) {
    if (value) {
      this.bindState = value;
    } else {
      this.bindState = null;
    }
    this.changePage(0);
  }
}
