/**
 * 播放器进度条
 */
import { Component, OnInit, HostListener, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { TrajectoryWithAlarm } from '../../core/vehicle-trajectory.model';

@Component({
  selector: 'app-player-controller',
  templateUrl: './player-controller.component.html',
  styleUrls: ['./player-controller.component.css']
})
export class PlayerControllerComponent implements OnInit {
  _lineArry: TrajectoryWithAlarm[];
  // 总点位
  totalPoint: number;
  // 循环-点位播放功能
  timer: number;
  // 当前速率
  currentRate = 1;
  // 是否显示 速率设定列表
  ifShowRateList: boolean;
  // 进度条总长度
  playerWidth: number;
  // 已播放的进度条
  whiteWidth = '0%';
  // 是否在播放状态
  isPlaying: boolean;
  @HostListener('window:click')
  onclick() {
    this.ifShowRateList = false;
  }
  // tslint:disable-next-line:member-ordering
  @ViewChild('player', { static: true }) player: ElementRef;
  // tslint:disable-next-line:member-ordering
  @Output('move') move: EventEmitter<number> = new EventEmitter();
  // 点位列表
  // tslint:disable-next-line:member-ordering
  @Input()
    set lineArry(list: TrajectoryWithAlarm[]) {
      this.totalPoint = list.length;
      this._lineArry = list;
      this.setTrace(0);
      this.playerWidth = this.player.nativeElement.clientWidth;
    }
    get lineArry() {
      return this._lineArry;
    }
  // 当前点
  // tslint:disable-next-line:member-ordering
  @Input() currentPoint = 0;

  constructor() { }

  ngOnInit() {
  }

  // 播放
  play() {
    if (this.currentPoint === this.totalPoint - 1) {
      this.setTrace(0);
    }
    this.isPlaying = true;
    this.timer = setInterval(() => this.setTrace(this.currentPoint + 1), 1000 / this.currentRate );
  }

  // 暂停
  pause() {
    if (this.timer) {
      this.isPlaying = false;
      clearInterval(this.timer);
      // this.timer = null;
    }
  }

  // 点击进度条并跳转
  jump(event: any) {
    this.pause();
    // 获取最新的位置点
    if (this.totalPoint > 1) {
      this.playerWidth = this.player.nativeElement.clientWidth;
      const tappedPercent = event.offsetX / this.playerWidth * 100;
      const point = tappedPercent * (this.totalPoint - 1) / 100;
      this.currentPoint = Math.round(point);
      this.setTrace(this.currentPoint);
    } else {
      const point = 0;
      this.currentPoint = Math.round(point);
      this.setTrace(this.currentPoint);
    }

  }

  // 设定点位
  setTrace(pointIndex: number) {
    if (pointIndex === this.totalPoint - 1) {
      this.pause();
    }
    if (this.totalPoint > 1) {
      this.currentPoint = pointIndex;
      this.move.emit(this.currentPoint);
      this.whiteWidth = this.currentPoint / (this.totalPoint - 1) * 100 + '%';
    } else {
      this.whiteWidth = '0%';
    }

  }

  // 设定速率
  setRate(rate: number) {
    this.pause();
    this.currentRate = rate;
    this.play();
  }
  // 显示速率列表
  showRateList() {
    this.ifShowRateList = true;
  }

}
