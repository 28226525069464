import { Jsonify } from './jsonify.interface';
import { Organization, Permission } from './permission.model';
import { WeChat } from './main.model';

/**
 * 用户管理服务，用于用户登录、授权
 */
export class InsuranceOrgAddBo {
  id: number;
  name: string;
  parent_id?: string;
  remark?: string;
  children_list?: Array<InsuranceOrgAddBo>;
}

export class InsuranceAuthorityAddBo {
  id: number;
  code: string;
  name: string;
  parent_id: string;
  remark: string;
  children_list?: Array<InsuranceAuthorityAddBo>;
}

export class InsuranceRoleAddBo {
  id: number;
  name: string;
  remark: string;
  insurance_authority_list?: Array<InsuranceAuthorityAddBo>;
}


export class InsuranceUser {
  id: number;
  login_name: string;
  user_name: string;
  org_id: number;
  insurance_org?: InsuranceOrgAddBo; // 机构
  insurance_role_list?: Array<InsuranceRoleAddBo>; // 权限
}

///////////////////////////////////////////
export class InsuranceOrgAddBoJson {
  id: number;
  name: string;
  parent_id?: string;
  remark?: string;
  children_list?: Array<InsuranceOrgAddBoJson>;
}

export class InsuranceAuthorityAddBoJson {
  id: number;
  name: string;
  parent_id: string;
  remark: string;
  children_list?: Array<InsuranceOrgAddBoJson>;
}

export class InsuranceRoleAddBoJson {
  id: number;
  code: string;
  name: string;
  parent_id: string;
  remark: string;
  children_list?: Array<InsuranceRoleAddBoJson>;
}

// export interface InsuranceUserJson {
//   id:number;
//   login_name:string;
//   user_name:string;
//   org_id:number;
//   insurance_org?:InsuranceOrgAddBoJson;
//   insurance_role_list?:InsuranceRoleAddBoJson;
// }

// export class InsuUser {
//   userId: number;
//   loginName: string;
//   userName: string;
//   orgId:number;
//   insuranceOrg:InsuranceOrgAddBo;
//   insuranceRoleList:InsuranceRoleAddBo;
//   //权限code
//   authorityList:InsuranceRoleAddBo[];


//   parse(json?: InsuranceUserJson): InsuranceUserJson {

//     this.userId = json.id;
//     this.loginName = json.login_name;
//     this.userName = json.user_name;
//     this.orgId= json.org_id;
//     this.insuranceOrg = json.insurance_org;
//     this.insuranceRoleList = json.insurance_role_list;
//     //权限code列表
//     this.authorityList = json.insurance_role_list[0].insurance_authority_list;

//     // if (json['roleList']) {
//     //   for (const obj of json['roleList']) {
//     //     this.roleList.push(new Role().parse(obj));
//     //   }
//     // }

//     return this;

//   }

//   toJsonObject(): InsuranceUserJson {
//     // 机构
//     const org_ids: string[] = [];
//     const role_list: string[] = [];
//     const permisson_code: string[] = [];
//     const org_list: any[] = [];

//     this.roleList.forEach((obj: Role) => {
//       role_list.push(obj.toJsonObject());
//     });
//     this.authorityList.forEach((obj: Permission) => {
//       perm_list.push(obj.toJsonObject());
//     });
//     this.orgList.forEach((obj: Organization) => {
//       org_list.push(obj.toJsonObject());
//     });
//     const json = {
//       user_id: this.userId,
//       login_name: this.loginName,
//       user_name: this.userName,
//       roleList: role_list,
//       org_ids: org_ids,

//       customer_authority_list: perm_list,
//       wechat_company: this.org ? this.org.toJsonObject() : undefined,
//       org_list: org_list,
//       create_time: this.createTime
//     };
//     return json;
//   }
// }

/**
 * 用户模型
 */
export class User {
  /**
   * 用户id
   */
  userId: string;

  /**
   * 账户名
   */
  loginName: string;

  /**
   * 昵称
   */
  userName: string;

  /**
   * 手机号
   */
  telephone: string;

  /**
   * 邮箱
   */
  email: string;

  /**
   * 密码
   */
  password: string;

  /**
   * 微信公众号
   */
  wxIds: WeChat[] = [];

  /**
   * 角色列表
   */
  roleList: Role[] = [];

  /**
   * 权限列表
   */
  authorityList: Permission[] = [];

  /**
   * 组织机构
   */
  org: Organization;

  /**
   * 组织机构 子公司列表 总公司有此字段
   */
  orgList: Organization[] = [];

  createTime: number;
  parse(json: any): User {
    if (json['user_id']) {
      this.userId = json['user_id'];
    } else if (json['id']) {
      this.userId = json['id'];
    }
    this.loginName = json['login_name'];
    this.userName = json['user_name'];
    this.telephone = json['telephone'];
    this.email = json['email'];
    this.password = json['password'];
    this.createTime = json['create_time'];
    if (json['roleList']) {
      for (const obj of json['roleList']) {
        this.roleList.push(new Role().parse(obj));
      }
    }
    if (json['wx_ids']) {
      for (const obj of json['wx_ids']) {
        this.wxIds.push(new WeChat().parse(obj));
      }
    }
    if (json['customer_authority_list']) {
      for (const obj of json['customer_authority_list']) {
        this.authorityList.push(new Permission().parse(obj));
      }
    }
    if (json['wechat_company']) {
      this.org = new Organization().parse(json['wechat_company']);
    }
    if (json['org_list']) {
      for (const obj of json['org_list']) {
        this.orgList.push(new Organization().parse(obj));
      }
    }
    /**
     * wxIds排序方法
     */
    const by = function (name) {
      return function (o, p) {
        let a, b;
        if (typeof o === 'object' && typeof p === 'object' && o && p) {
          a = o[name];
          b = p[name];
          if (a === b) {
            return 0;
          }
          if (typeof a === typeof b) {
            return a > b ? -1 : 1;
          }
          return typeof a > typeof b ? -1 : 1;
        } else {
          throw new Error(('error'));
        }
      };
    };

    this.wxIds = this.wxIds.sort(by('createTime'));
    return this;
  }

  toJsonObject(): any {
    const org_ids: string[] = [];
    const wx_ids: string[] = [];
    const role_list: string[] = [];
    const perm_list: string[] = [];
    const org_list: any[] = [];
    this.wxIds.forEach((obj: WeChat) => {
      wx_ids.push(obj.toJsonObject());
    });
    this.roleList.forEach((obj: Role) => {
      role_list.push(obj.toJsonObject());
    });
    this.authorityList.forEach((obj: Permission) => {
      perm_list.push(obj.toJsonObject());
    });
    this.orgList.forEach((obj: Organization) => {
      org_list.push(obj.toJsonObject());
    });
    const json = {
      user_id: this.userId,
      login_name: this.loginName,
      user_name: this.userName,
      telephone: this.telephone,
      email: this.email,
      password: this.password,
      roleList: role_list,
      org_ids: org_ids,
      wx_ids: wx_ids,
      customer_authority_list: perm_list,
      wechat_company: this.org ? this.org.toJsonObject() : undefined,
      org_list: org_list,
      create_time: this.createTime
    };
    return json;
  }
}

/**
 * 登录信息
 */
export class LoginInfo implements Jsonify<LoginInfo> {

  // 登录过期时间
  // expiration: Date;

  // 是否登录成功
  // isLoggedIn: boolean;

  // 用户
  user: InsuranceUser;

  // Session
  // session: string;

  /**
   * 是否记住登录
   */
  // isRemember: boolean;


  parse(json: any): LoginInfo {
    // this.expiration = new Date(json['expiration']);
    // this.isLoggedIn = json['isLoggedIn'];
    // this.session = json['session'];
    // this.isRemember = json['isRemember'];
    this.user = json.user;
    return this;
  }

  toJsonObject(): any {
    return {
      // expiration: this.expiration.getTime(),
      // isLoggedIn: this.isLoggedIn,
      // session: this.session,
      // isRemember: this.isRemember,
      user: this.user
    };
  }
}

/**
 * 角色
 */
export class Role implements Jsonify<Role> {

  // 登录过期时间
  id: string;

  // 角色代码
  roleCode: string;

  // 角色名称
  roleName: string;

  parse(json: any): Role {
    this.id = json['id'];
    this.roleCode = json['roleCode'];
    this.roleName = json['roleName'];
    return this;
  }

  toJsonObject(): any {
    return {
      id: this.id,
      roleCode: this.roleCode,
      roleName: this.roleName
    };
  }
}

/**
 * 月报状态
 */
export class ReportStatus implements Jsonify<ReportStatus> {

  // 机构Id
  companyd: string;

  // 月份
  month: string;

  // 状态
  status: string;

  parse(json: any): ReportStatus {
    this.companyd = json['company_id'];
    this.month = json['month'];
    this.status = json['status'];
    return this;
  }

  toJsonObject(): any {
    return {
      company_id: this.companyd,
      month: this.month,
      status: this.status
    };
  }
}
