import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Vehicle, GpsParams, GpsTrajectoryRes, VehicleSimplejson } from './vehicle-trajectory.model';
import { AuthService } from 'src/app/core/service/auth/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class VehicleTrajectoryService {
  readonly baseUrl = environment.newBaseUrl;

  constructor( private httpClient: HttpClient,
    private  auth:AuthService) {
  }

  getVehicleNew(id: number, license_card: string,): Observable<Vehicle[]> {
    const Jwt: string = this.auth.jwt;
    let queryParameters = new HttpParams();

    if (license_card !== null && license_card !== undefined) {
      queryParameters = queryParameters.set('license_card', <any>license_card);
    }

    let headers = new HttpHeaders();
    const httpHeaderAccepts: string[] = ["application/json"];
    headers = headers.set("Accept", httpHeaderAccepts);
    headers = headers.set("Authorization", "Bearer " + Jwt);

    return this.httpClient.get<VehicleSimplejson[]>(`${this.baseUrl}/insurance-orgs/${id}/vehicles/simple`, {
      headers: headers,
      params: queryParameters
    }).pipe(
        map(result => result.map(item => new Vehicle (item)))
      
    );
  }

  getGpsTrajectoryNew(query: GpsParams): Observable<GpsTrajectoryRes> {
    const Jwt: string = this.auth.jwt;
    let queryParameters = new HttpParams();

    if (query.license_card) {
      queryParameters = queryParameters.set("license_card", <any>query.license_card);
    }

    if (query.license_color) {
      queryParameters = queryParameters.set("license_color", <any>query.license_color);
    }

    if (query.start_time) {
      queryParameters = queryParameters.set("start_time", <any>query.start_time.toISOString());
    }

    if (query.end_time) {
      queryParameters = queryParameters.set("end_time", <any>query.end_time.toISOString());
    }

    let headers = new HttpHeaders();
    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);
    headers = headers.set("Authorization", "Bearer " + Jwt);

    return this.httpClient.get<GpsTrajectoryRes>(`${this.baseUrl}/vehicles/trajectory-with-alarm`, {
      headers: headers,
      params: queryParameters
    });
  }

}
