import { Subscription } from 'rxjs';
import { HttpService } from '../core/network/http.service';
import { UserService } from '../core/service/user.service';
import { IndexService, MonthReportParams, OrgMonthlyReportSummary } from './index.service';
/**
 * Created by AGUANG on 2017/2/15.
 */
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BasePage } from '../core/base-page/base-page';
import { AuthService } from '../core/service/auth/auth.service';
import { ErrorService } from '../ErrorService/error.service';
import { StorageService } from '../core/storage/storage.service';
import { InsuranceUser } from '../core/model/user.model';
import { switchMap } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent extends BasePage implements OnInit {
  corpName: string;
  selectMonth: { key: string; value: number; }[];
  selectYear: any[] = [];
  // 总警告
  warningTotal = 0;
  // 总提醒
  remindTotal = 0;
  // 当前月
  currentMonth = 0;
  // 当前选中运营商
  currentId = '';

  showChangePassword = false;

  ifShowMap = false;

  // indexOption: IndexOption = new IndexOption();
  indexOption: OrgMonthlyReportSummary;
  // 创建时间
  createTime: number;

  // 2019-1-7
  lastMonthDate: Date;
  lastMonth: string;
  orgId: number;
  currentUser: InsuranceUser;

  authCodeTree: string[] = [];

  constructor(
    public indexService: IndexService,
    public userService: UserService,
    private httpService: HttpService,
    private router: Router,
    private authService: AuthService,
    private error: ErrorService,
    private storage: StorageService) {
    super();

  }

  ngOnInit() {
    this.userService.getUserInfo(this.authService.getLocalJWT()).subscribe(user => {
      if (user) {
        if (user.insurance_role_list && user.insurance_role_list.length > 0) {
          user.insurance_role_list.forEach(role => {
            if (role.insurance_authority_list) {
              this.getAuthTree(role.insurance_authority_list);
            }
          });
        }
        this.currentUser = user;
        this.orgId = this.currentUser.org_id;
        this.getLastMonthReport(this.orgId);
      }
    });
  }

  getAuthTree(auth) {
    auth.forEach(r => {
      if (this.authCodeTree.indexOf(r.code) < 0) {
        this.authCodeTree.push(
          r.code
        );
      }
      if (r.children_list && r.children_list.length > 0) {
        this.getAuthTree(r.children_list);
      }
    });
  }

  getLastMonthReport(orgid) {
    // 时间插件,走api-gateway查询月报生成的月份
    this.userService.getReportStatusNew(orgid).subscribe(
      r => {
        if (!r) { return; }
        this.lastMonth = r;
        this.lastMonthDate = new Date(this.lastMonth + '-01');
        for (let i = 2018; i <= this.lastMonthDate.getFullYear(); i++) {
          this.selectYear.push({
            value: i,
            name: i + '年'
          });
        }
        this.changeMonthList(this.lastMonthDate.getFullYear());
        this.fresh();
      }, e => {
        this.showErrorMessage(this.error.getErrorText(e));
      }
    );
  }

  fresh() {
    const option: MonthReportParams = { month: this.lastMonth };
    let total = 0;
    this.indexService.getIndexOptionNew(this.orgId, option).subscribe(
      r => {
        this.indexOption = r;
        total = r.fatigue_msg_count + r.bad_weather_count + r.overspeed_msg_count + r.overspeed_msg_count + r.equipment_failure_count;
        this.warningTotal = total;
        this.remindTotal = r.madia_count + r.daily_summary_count + r.bind_remind_count;
        this.currentMonth = this.lastMonthDate.getMonth() + 1;
      }, e => {
        if (e.error && e.error.errcode && e.error.errcode === 'report_not_create') {
          this.showErrorMessage('报告尚未生成,请重新选择时间');
        } else {
          this.showErrorMessage(this.error.getErrorText(e));
        }
      }

    );
  }

  // 选择年
  onYearSelect(val) {
    const yTimeStr = new Date(this.lastMonth).setFullYear(val);
    const middleValue = new Date(yTimeStr).getMonth() + 1;
    const ymonth = middleValue < 10 ? '0' + middleValue : middleValue;
    this.lastMonth = val + '-' + ymonth;
    this.changeMonthList(val);
    this.fresh();
  }

  // 选择月份
  onMonthSelect(val) {
    const timeString = new Date(this.lastMonth).setMonth(val - 1);
    if (val < 10) { val = '0' + val; }
    const sYear = new Date(timeString).getFullYear();
    this.lastMonth = sYear + '-' + val;
    this.fresh();
  }


  changeMonthList(year) {
    this.selectMonth = [];
    if (parseInt(year, 10) - parseInt(new Date().getFullYear() + '', 10) < 0) {
      const selectMonthList = [];
      for (let i = 0; i < 12; i++) {
        selectMonthList.push({ name: i + 1 + '月', value: i + 1 });
      }
      return this.selectMonth = selectMonthList;
    } else {
      const selectMonthList = [];
      for (let i = 0; i <= this.lastMonthDate.getMonth(); i++) {
        selectMonthList.push({ name: i + 1 + '月', value: i + 1 });
      }
      return this.selectMonth = selectMonthList;
    }

  }

  // 生成月份列表
  createMonth(year) {
    this.selectMonth = [];
    if (year < new Date(this.lastMonth).getFullYear()) {
      for (let i = 0; i < 12; i++) {
        this.selectMonth.push({
          key: i + 1 + '月',
          value: i + 1
        });
      }
    } else {
      for (let i = 0; i < 12; i++) {
        if (i <= new Date(this.lastMonth).getMonth()) {
          this.selectMonth.push({
            key: i + 1 + '月',
            value: i + 1
          });
        } else {
          break;
        }
      }
    }
  }

  // 是否有权限
  isHasAuthrity(code: string): boolean {
    return this.authCodeTree.indexOf(code) >= 0;
  }

}
