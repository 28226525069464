import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
@Component({
  selector: 'app-my-select',
  templateUrl: './my-select.component.html',
  styleUrls: ['./my-select.component.css']
})
export class MySelectComponent implements OnInit {
  // 伸缩中
  playing: boolean;
  // 显示下拉
  hide = true;
  // 首项下圆角
  bottomCircle = true;
  // selectObj:SelectOption = new SelectOption("","");
  // 指定显示字段参数名
  @Input() key = 'key';
  // 指定值字段参数名
  @Input() value = 'value';
  // 宽度
  @Input() width = '100%';
  // 是否圆角
  @Input() ifCircle = false;
  // placeholder
  @Input() myPlaceholder = '';
  // 参数
  @Input() selectOptionList: any[] = [];
  // 输出
  @Output() emit = new EventEmitter();

  @HostListener('window:click'/*, ['$event']*/)
  onClick() {
    if (!this.playing) {
      this.hide = true;
      this.playing = true;
      if (!this.bottomCircle) {
        setTimeout(() => {
          this.bottomCircle = true;
        }, 300);
      }
      setTimeout(() => {
        this.playing = false;
      }, 300);
    }
  }

  constructor(  ) {  }

  ngOnInit(  ) {  }

  send(obj) {
    // this.myPlaceholder = obj.name;
    this.emit.emit(obj);
  }

  timeOut() {
    if (!this.playing) {
      this.hide = !this.hide;
      this.playing = true;
      if (!this.bottomCircle) {
        setTimeout(() => {
          this.bottomCircle = true;
        }, 300);
      } else {
        this.bottomCircle = false;
      }
      setTimeout(() => {
        this.playing = false;
      }, 300);
    }
  }


}
