import { HeaderComponent } from "./header/header.component";
import { MyCheckBoxComponent } from "./my-check-box/my-check-box.component";
import { MySelectComponent } from "./my-select/my-select.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AutoHeightSetDirective } from "./auto-height-set/auto-height-set.directive";
import { TelephoneValidatorDirective } from "./telephoneValidator/telephone-validator.directive";
import { DialogComponent } from "./dialog/dialog.component";
import { ConfirmComponent } from "./confirm/confirm.component";
import { ScrollToTopDirective } from "./scroll-to-top/scroll-to-top.directive";
import { ScrollBottomDetectDirective } from "./scroll-to-bottom/scroll-to-bottom.directive";
import { PaginatorComponent } from "./paginator/paginator.component";
import { MyPaginatorComponent } from "./myPaginator/paginator.component";
import { PermissionService } from "./permissions/permission.service";
import { NoPressionPageComponent } from "./no-pression-page/no-pression-page.component";
/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */
@NgModule({
  imports: [CommonModule, RouterModule, FormsModule],
  declarations: [
    AutoHeightSetDirective,
    TelephoneValidatorDirective,
    DialogComponent,
    ConfirmComponent,
    ScrollToTopDirective,
    ScrollBottomDetectDirective,
    MySelectComponent,
    MyCheckBoxComponent,
    HeaderComponent,
    PaginatorComponent,
    MyPaginatorComponent,
    NoPressionPageComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    AutoHeightSetDirective,
    TelephoneValidatorDirective,
    ScrollBottomDetectDirective,
    DialogComponent,
    ConfirmComponent,
    ScrollToTopDirective,
    MySelectComponent,
    MyCheckBoxComponent,
    HeaderComponent,
    PaginatorComponent,
    MyPaginatorComponent,
    NoPressionPageComponent
  ],
  providers:[PermissionService]
})
export class SharedModule {}
