import {  TrajectoryWithAlarm, VehicleAlarm } from './../../core/vehicle-trajectory.model';
import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {
  _target: TrajectoryWithAlarm = new TrajectoryWithAlarm();
  @Input()
  set target(target: TrajectoryWithAlarm) {
    if (target) {
      this._target = target;
      this.setTarget(target);
    }
  }
  get target(): TrajectoryWithAlarm {
    return this._target;
  }
  @ViewChild('widnowContent', { static: true }) widnowContent: HTMLDivElement;
  remindList: VehicleAlarm[] = [];
  mediaList: VehicleAlarm[] = [];
  constructor() { }

  ngOnInit() {
  }

  setTarget(target: TrajectoryWithAlarm) {
    this.remindList = target.alarm_data.filter(e => e.alarm_type !== 'adas_alarm');
    this.mediaList = target.alarm_data.filter(e => e.alarm_type === 'adas_alarm');
  }
}
