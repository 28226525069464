import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdasPorjectComponent } from './adas-porject.component';
import { AuthGuardService } from '../core/service/auth/auth-guard.service';


const routes: Routes = [
  {
    path: 'adas',
    component: AdasPorjectComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdasPorjectRoutingModule { }
