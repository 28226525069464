import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../core/service/auth/auth.service';
export class PdfResult {
  year: number;
  month: number;
  pageCount: number;
  singlePageWidth: number;
  singlePageHeight: number;
  entities: {
    name: string;
    webPageUrl: string;
  }[];
}
export class ReportData {
  month: number; // 月份
  trusteeship_count: number; // 托管车辆数
  online_rate: number; // 上线率
  driving_km_avg: number; // 总里程(公里)
  driving_hour_avg: number; // 总时长(小时)
  driving_km_overspeed_avg: number; // 超速里程(公里)
  driving_km_overspeed_avg_rate: number; // 超速里程占比(%)
  hundred_km_overspeed_count_avg: number; // 百公里超速次数
  fatigue_timelength_hour_avg: number; // 疲劳时长(小时)
  org_fatigue_timelength_rate_avg: number; // 疲劳时长占比(%)
  fatigue_count_avg: number; // 疲劳次数
  org_hundred_km_overspeed_count_avg: number; // 百公里超速次数
  should_bind_colonel_count: number; // 团队长应绑定数
  should_bind_captain_count: number; // 车队长应绑定数
  bind_colonel_count: number; // 团队长已绑定数
  bind_captain_count: number; // 车队长已绑定数
  message_count: number; // 高危短信次数
  phone_count: number; // 高危电话次数
  pre_trusteeship_count: number;
  pre_online_rate: number;
  pre_driving_km_avg: number;
  pre_driving_hour_avg: number;
  pre_driving_km_overspeed_avg: number;
  pre_driving_km_overspeed_avg_rate: number;
  pre_hundred_km_overspeed_count_avg: number;
  pre_fatigue_timelength_hour_avg: number;
  pre_org_fatigue_timelength_rate_avg: number;
  pre_fatigue_count_avg: number;
  pre_org_hundred_km_overspeed_count_avg: number;
  pre_night_km_rate: number; // 去年夜间里程(公里)
  pre_evening_km_rate: number; // 去年傍晚里程(公里)
  pre_afternoon_km_rate: number; // 去年下午里程(公里)
  pre_day_km_rate: number; // 去年上午里程(公里)
  pre_dawn_km_rate: number; // 去年凌晨里程(公里)
  pre_highway_km_rate: number; // 去年高速公路里程(公里)
  pre_nationalway_km_rate: number; // 去年国道里程(公里)
  pre_provinceway_km_rate: number; // 去年省道里程(公里)
  pre_otherway_km_rate: number; // 去年其他道路里程(公里)

  night_km_rate: number; // 夜间里程(公里)
  evening_km_rate: number; // 傍晚里程(公里)
  afternoon_km_rate: number; // 下午里程(公里)
  day_km_rate: number; // 上午里程(公里)
  dawn_km_rate: number; // 凌晨里程(公里)
  highway_km_rate: number; // 高速公路里程(公里)
  nationalway_km_rate: number; // 国道里程(公里)
  provinceway_km_rate: number; // 省道里程(公里)
  otherway_km_rate: number; // 其他道路里程(公里)

  pre_message_count: number;
  pre_phone_count: number;
  country_hundred_km_overspeed_count_avg: number; // 全国平均百公里超速
  country_fatigue_count_avg: number; // 全国平均疲劳次数
  country_driving_km_overspeed_avg_rate: number; // 全国平均超速里程占比
  country_org_fatigue_timelength_rate_avg: number; // 全国平均疲劳占比
}
export class Company {
  company_name: string;
  vehicle_count: number;
  score: number;
  pre_score: number;
}

@Injectable()
export class LevelInsuranceReportService {
  ChangeSize = new Subject<boolean>();
  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }
  getData(option): Observable<ReportData[]> {
    let headers = new HttpHeaders();
    const Jwt: string = this.auth.jwt;
    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);
    headers = headers.set('Authorization', 'Bearer ' + Jwt);
    return this.http.get<ReportData[]>(`${environment.newBaseUrl}/noauth/year-report-statistics`, {
      params: option
    });
  }

  getRank(option): Observable<Company[]> {
    let headers = new HttpHeaders();
    const Jwt: string = this.auth.jwt;
    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);
    headers = headers.set('Authorization', 'Bearer ' + Jwt);
    return this.http.get<Company[]>(`${environment.newBaseUrl}/noauth/highrisk-companys`, {
      params: option
    });

  }

  sendPdf(option: PdfResult): Observable<Blob> {
    return this.http.post(`${environment.PdfUrl}/pdf/institutereports`, option, {
      observe: 'body',
      responseType: 'blob'
    }).pipe(
      timeout(600000),
      map(r => {
        return r;
      })
    );
  }
}
