import { Component, ElementRef, HostListener, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import * as echarts from 'echarts';
export class PercentageRoadData {
  pre_highway_km_rate: number[] = [];
  pre_nationalway_km_rate: number[] = [];
  pre_provinceway_km_rate: number[] = [];
  pre_otherway_km_rate: number[] = [];
}
@Component({
  selector: 'app-percentage-road',
  templateUrl: './percentage-road.component.html',
  styleUrls: ['./percentage-road.component.css']
})
export class PercentageRoadComponent implements OnInit, AfterViewInit {
  title = '道路结构里程占比';
  @ViewChild('chart', { static: true }) _chart: ElementRef;
  chart: echarts.ECharts;
  remark: string;
  onResize() {
    if (this.chart) { this.chart.resize(); }
  }
  // tslint:disable-next-line:member-ordering
  @Input() date = new Date();
  // tslint:disable-next-line:member-ordering
  _data: PercentageRoadData = new PercentageRoadData;
  @Input()
  set data(data: PercentageRoadData) {
    this._data = data;
    if (data) {
      this.setChart(data);
      this.setRemark();
    }
  }
  get data(): PercentageRoadData {
    return this._data;
  }
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.chart = echarts.init(this._chart.nativeElement);
  }
  setRemark() {
    this.remark = `根据托管车辆道路结构分析，`;
    const month = this.date.getMonth();
    let val = '';
    if (this.data.pre_highway_km_rate[month] >= 25) {
      val += '高速公路';
    }
    if (this.data.pre_nationalway_km_rate[month] >= 25) {
      val += '国道';
    }
    if (this.data.pre_provinceway_km_rate[month] >= 25) {
      val += (val ? '、' : '') + '省道';
    }
    if (this.data.pre_otherway_km_rate[month] >= 25) {
      val += (val ? '、' : '') + '其他道路';
    }
    this.remark += `${val}占比较高，需重点关注。`;
  }
  setChart(data: PercentageRoadData) {
    const month = this.date.getMonth();
    const current = [], last = [];
    for (let i = 0; i <= month; i++) {
      current.push(`${this.date.getFullYear()}-${i + 1}`);
    }
    for (let i = 0; i < 11 - month; i++) {
      last.push(`${this.date.getFullYear() - 1}-${month + i + 2}`);
    }
    this.chart.resize();
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        },
        // formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      legend: {
        data: ['其他道路', '省道里程', '国道里程', '高速公路里程']
      },
      grid: {
        left: '15px',
        right: '15px',
        bottom: '15px',
        top: '40px',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: last.concat(current)
        }
      ],
      yAxis: [
        {
          type: 'value',
          show: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        }
      ],
      series: [
        {
          name: '高速公路里程',
          type: 'bar',
          barWidth: 40,
          stack: '道路结构',
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'inside',
            color: 'white',
            formatter: '{c}%'
          },
          data: data.pre_highway_km_rate,
          itemStyle: {
            color: '#0091FF'
          }
        },
        {
          name: '国道里程',
          type: 'bar',
          stack: '道路结构',
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'inside',
            color: 'white',
            formatter: '{c}%'
          },
          data: data.pre_nationalway_km_rate,
          itemStyle: {
            color: '#F14B46'
          }
        },
        {
          name: '省道里程',
          type: 'bar',
          stack: '道路结构',
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'inside',
            color: 'white',
            formatter: '{c}%'
          },
          data: data.pre_provinceway_km_rate,
          itemStyle: {
            color: '#F7B500'
          }
        },
        {
          name: '其他道路',
          type: 'bar',
          stack: '道路结构',
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'inside',
            color: 'white',
            formatter: '{c}%'
          },
          data: data.pre_otherway_km_rate,
          itemStyle: {
            color: '#6DD400'
          }
        }
      ]
    };
    this.chart.setOption(option);
  }
}
