import { Output } from '@angular/core';
import { Component, Input, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  @Input() date = new Date();
  @Input() insurance = '';
  @Input() isPdfMode: boolean;
  @Output() exportPdf = new EventEmitter;
  @Input() shown : boolean = true
  constructor() { }

  ngOnInit() {
    this.shown = JSON.parse(window.location.search.split('=')[1]);
  }
  createPdf() {
    this.exportPdf.emit();
  }
}
