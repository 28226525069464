import { IndexOption, ReqIndexOption } from './index.protocol';
import { HttpService } from '../core/network/http.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from '../core/service/auth/auth.service';


export class MonthReportParams {
  month: string;
}

export interface OrgLastMonthly {
  last_month: string;
}

export interface OrgMonthlyReportSummary {
  fatigue_msg_count: number;
  overspeed_msg_count: number;
  vehicle_count: number;
  bad_weather_count: number;
  equipment_failure_count: number;
  danger_way_count: number;
  madia_count: number;
  daily_summary_count: number;
  bind_remind_count: number;
  bind_user_count: number;
  user_count: number;
}

@Injectable()
export class IndexService {

  readonly baseUrl = environment.newBaseUrl;

  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient,
    private auth: AuthService) {
  }

  getIndexOption(id: number, date: number): Observable<IndexOption> {
    return this.httpService.request(new ReqIndexOption(id, date))
      .pipe(map(r => r.option));
  }

   // 机构月报及公众号绑定统计
   getIndexOptionNew(id: number, params: MonthReportParams): Observable<OrgMonthlyReportSummary> {
    const Jwt: string = this.auth.jwt;

    let queryParameters = new HttpParams();
    if (params.month) {
       queryParameters = queryParameters.set('month', <any>( params.month));
    }

    let headers = new HttpHeaders();
    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);
    headers = headers.set('Authorization', 'Bearer ' + Jwt);

    const url = `${this.baseUrl}/insurance-orgs/${id}/monthly-report/summary`;

    return this.httpClient.get<OrgMonthlyReportSummary>(url, {
      headers: headers,
      params: queryParameters
    });
   }




}
