/**
 * Created by AGUANG on 2017/6/14.
 */
import { Jsonify } from './jsonify.interface';

/**
 * 微信公众号对象
 */
export class WeChat implements Jsonify<WeChat> {
  /**
   * 公众号id
   */
  wxId: string;

  /**
   * 公众号名称
   */
  name: string;

  /**
   * 绑定的运营企业
   */
  corpList: Corp[] = [];

  /**
   *创建的时间
   */
  createTime: number;

  isSelected = false;

  parse(json: any): WeChat {
    this.wxId = json['wx_id'];
    this.name = json['name'];
    this.createTime = json['createTime'];
    if (json['corpList']) {
      for (const obj of json['corpList']) {
        this.corpList.push(new Corp().parse(obj));
      }
    }
    return this;
  }

  toJsonObject(): any {
    const json = {
      wx_id: this.wxId,
      name: this.name,
      createTime: this.createTime
    };
    return json;
  }
}

/**
 * 企业树对象
 */
export class Corp implements Jsonify<Corp> {

  // 部门id
  id: string;

  // 部门名称
  corpName: string;

  // 下级部门对象数组
  children: Corp[] = [];

  // 页面显示用
  isSelected = false;

  parse(json: any): Corp {
    this.id = json['corp_id'];
    this.corpName = json['corp_name'];
    if (json['system_level'] === 1) {
      const index = json['corp_id'].split('_')[0];
      this.corpName = json['corp_name'] + '(' + index + ')';
    }
    if (json['children']) {
      for (const obj of json['children']) {
        this.children.push(new Corp().parse(obj));
      }
    }
    return this;
  }

  toJsonObject(): any {
    return {
    };
  }
}


/**
 * 团/车队长绑定情况对象
 */
export class LeaderBind implements Jsonify<LeaderBind> {

  // 企业id
  companyId: string;

  // 企业名称
  company: string;

  // 名称
  name: string;

  // 电话
  telephone: string;

  // 角色名称
  tagName: string;

  // 绑定情况
  bindStatus: string;

  // 支公司名称
  subsidiary: string;

  belongCompany: string;

  salesman: string;

  subCount: number;

  children: LeaderBind[] = [];
  // 授权情况
  authorization: number;
  // 授权时间
  authorizationTime: number;
  // 微信用户id
  wxUserId: string;

  type?;
  bind_status?;
  captain_count?;
  insurance_org_name?;
  parse(json: any): LeaderBind {
    this.companyId = json['corp_id'];
    this.name = json['name'];
    this.company = json['company_name'];
    this.telephone = json['telephone'];
    this.tagName = json['tag_name'];
    this.bindStatus = json['bind_status'];
    this.subsidiary = json['subsidiary'];
    this.subCount = json['sub_count'];
    this.belongCompany = json['belong_company'];
    this.salesman = json['salesman'];
    this.authorization = json['authorization'];
    this.authorizationTime = json['authorization_time'];
    this.wxUserId = json['wechat_user_id'];
    if (json['children']) {
      for (const obj of json['children']) {
        this.children.push(new LeaderBind().parse(obj));
      }
    }
    return this;
  }

  toJsonObject(): any {
    return {
    };
  }
}





