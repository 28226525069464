import { Component, ElementRef, HostListener, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import * as echarts from 'echarts';
export class PercentageMileageData {
  pre_dawn_km_rate: number[] = [];
  pre_day_km_rate: number[] = [];
  pre_afternoon_km_rate: number[] = [];
  pre_evening_km_rate: number[] = [];
  pre_night_km_rate: number[] = [];
}
@Component({
  selector: 'app-percentage-mileage',
  templateUrl: './percentage-mileage.component.html',
  styleUrls: ['./percentage-mileage.component.css']
})
export class PercentageMileageComponent implements OnInit, AfterViewInit {
  title = '分时段里程占比';
  @ViewChild('chart', { static: true }) _chart: ElementRef;
  chart: echarts.ECharts;
  remark: string;
  onResize() {
    if (this.chart) { this.chart.resize(); }
  }
  // tslint:disable-next-line:member-ordering
  @Input() date = new Date();
  // tslint:disable-next-line:member-ordering
  _data: PercentageMileageData = new PercentageMileageData;
  @Input()
  set data(data: PercentageMileageData) {
    this._data = data;
    if (data) {
      this.setChart(data);
      this.setRemark();
    }
  }
  get data(): PercentageMileageData {
    return this._data;
  }
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.chart = echarts.init(this._chart.nativeElement);
  }
  setRemark() {
    this.remark = `根据托管车辆分时段里程统计，`;
    const month = this.date.getMonth();
    let val = '';
    if (this.data.pre_dawn_km_rate[month] >= 20) {
      val += '凌晨';
    }
    if (this.data.pre_day_km_rate[month] >= 20) {
      val += (val ? '、' : '') + '上午';
    }
    if (this.data.pre_afternoon_km_rate[month] >= 20) {
      val += (val ? '、' : '') + '下午';
    }
    if (this.data.pre_evening_km_rate[month] >= 20) {
      val += (val ? '、' : '') + '傍晚';
    }
    if (this.data.pre_night_km_rate[month] >= 20) {
      val += (val ? '、' : '') + '夜间';
    }
    this.remark += `${val}时段占比较高，需重点关注。`;
  }
  setChart(data: PercentageMileageData) {
    const month = this.date.getMonth();
    const current = [], last = [];
    for (let i = 0; i <= month; i++) {
      current.push(`${this.date.getFullYear()}-${i + 1}`);
    }
    for (let i = 0; i < 11 - month; i++) {
      last.push(`${this.date.getFullYear() - 1}-${month + i + 2}`);
    }
    this.chart.resize();
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      legend: {
        data: ['凌晨：0-6点', '上午：7-12点', '下午：13-17点', '傍晚：18-20点', '夜间：21-23点']
      },
      grid: {
        left: '15px',
        right: '15px',
        bottom: '15px',
        top: '40px',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: last.concat(current)
        }
      ],
      yAxis: [
        {
          type: 'value',
          show: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        }
      ],
      series: [
        {
          name: '凌晨：0-6点',
          type: 'bar',
          barWidth: 40,
          stack: '分时段里程占比',
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'inside',
            color: 'white',
            formatter: '{c}%'
          },
          data: data.pre_dawn_km_rate,
          itemStyle: {
            color: '#AED2FF'
          }
        },
        {
          name: '上午：7-12点',
          type: 'bar',
          stack: '分时段里程占比',
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'inside',
            color: 'white',
            formatter: '{c}%'
          },
          data: data.pre_day_km_rate,
          itemStyle: {
            color: '#72B1FF'
          }
        },
        {
          name: '下午：13-17点',
          type: 'bar',
          stack: '分时段里程占比',
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'inside',
            color: 'white',
            formatter: '{c}%'
          },
          data: data.pre_afternoon_km_rate,
          itemStyle: {
            color: '#3D7DF7'
          }
        },
        {
          name: '傍晚：18-20点',
          type: 'bar',
          stack: '分时段里程占比',
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'inside',
            color: 'white',
            formatter: '{c}%'
          },
          data: data.pre_evening_km_rate,
          itemStyle: {
            color: '#0A3DE2'
          }
        },
        {
          name: '夜间：21-23点',
          type: 'bar',
          stack: '分时段里程占比',
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'inside',
            color: 'white',
            formatter: '{c}%'
          },
          data: data.pre_night_km_rate,
          itemStyle: {
            color: '#0B32B0'
          }
        }
      ]
    };
    this.chart.setOption(option);
  }
}

