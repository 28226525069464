import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LevelInsuranceReportRoutingModule } from './level_insurance-report-routing.module';
import { LevelInsuranceReportComponent } from './level_insurance-report.component';
import { IndexComponent } from './component/index/index.component';
import { MenuComponent } from './component/menu/menu.component';
import { StatisticsComponent } from './component/statistics/statistics.component';
import { AnalysisComponent } from './component/analysis/analysis.component';
import { CompanyComponent } from './component/company/company.component';
import { EndComponent } from './component/end/end.component';
import { ProjectionComponent } from './component/projection/projection.component';
import { VehicleOnlineComponent } from './component/statistics/vehicle-online/vehicle-online.component';
import { WxBindingComponent } from './component/statistics/wx-binding/wx-binding.component';
import { MilesAndTimelengthComponent } from './component/analysis/miles-and-timelength/miles-and-timelength.component';
import { PercentageMileageComponent } from './component/analysis/percentage-mileage/percentage-mileage.component';
import { PercentageRoadComponent } from './component/analysis/percentage-road/percentage-road.component';
import { PercentageOverSpeedComponent } from './component/analysis/percentage-over-speed/percentage-over-speed.component';
import { OverSpeedTimesComponent } from './component/analysis/over-speed-times/over-speed-times.component';
import { FatigueComponent } from './component/analysis/fatigue/fatigue.component';
import { FatigueTimesComponent } from './component/analysis/fatigue-times/fatigue-times.component';
import { ManualReminderComponent } from './component/analysis/manual-reminder/manual-reminder.component';
import { RankComponent } from './component/company/rank/rank.component';
import { LevelInsuranceReportService } from './level_insurance-report.service';


@NgModule({
  declarations: [
    LevelInsuranceReportComponent, IndexComponent, MenuComponent,
    StatisticsComponent, AnalysisComponent, CompanyComponent,
    EndComponent, ProjectionComponent, VehicleOnlineComponent,
    WxBindingComponent, MilesAndTimelengthComponent, PercentageMileageComponent,
    PercentageRoadComponent, PercentageOverSpeedComponent, OverSpeedTimesComponent,
    FatigueComponent, FatigueTimesComponent, ManualReminderComponent,
    RankComponent
  ],
  imports: [
    CommonModule,
    LevelInsuranceReportRoutingModule
  ],
  providers: [
    LevelInsuranceReportService
  ]
})
export class LevelInsuranceReportModule { }
