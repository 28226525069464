import { Component } from '@angular/core';
import { HttpService } from './core/network/http.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private httpService: HttpService) {

    // function showLoading() {
    //   document.getElementById('loading').style.display = 'block';
    // }

    // function hideLoading() {
    //   document.getElementById('loading').style.display = 'none';
    // }

    // this.httpService.beforeRequests.push(showLoading);
    // this.httpService.afterRequests.push(hideLoading);
  }
}
