import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AsideComponent } from './aside/aside.component';
import { PublicComponent } from './public.component';
import { SharedModule } from '../shared/shared.module';
import { PublicRoutingModule } from './public.routing.module';

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */
@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, PublicRoutingModule, FormsModule],
  declarations: [ AsideComponent, PublicComponent],
  providers: [],
  exports: [CommonModule, RouterModule, AsideComponent, PublicComponent]
})
export class PublicModule {
}
