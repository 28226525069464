// 模糊搜索车辆对象
export interface VehicleSimplejson {
  vehicle_id: string;
  license_card: string;
  license_color: string;
}

export class Vehicle {
  vehicleId?: string;
  licenseCard = '';
  licenseColor = '';

  constructor(json?: VehicleSimplejson) {
    if (json) {
      this.parse(json);
    }
  }

  parse(json: VehicleSimplejson): Vehicle {
    this.vehicleId = json.vehicle_id;
    this.licenseCard = json.license_card;
    this.licenseColor = json.license_color;
    return this;
  }

  // 序列化为json
  toJsonObject(): VehicleSimplejson {
    return {
      vehicle_id: this.vehicleId,
      license_card: this.licenseCard,
      license_color: this.licenseColor
    };

  }

}

// 请求GPS点位参数
export class GpsTrajectoryParams {
  vehicleId: string;
  startTime: Date;
  endTime: Date;
}

export class GpsParams {
  license_card: string;
  license_color: string;
  start_time: Date;
  end_time: Date;
}


export class Driver {
  name: string;
}

export class VehicleAlarm {
  alarm_type: string;
  alarm_time: string;
  alarm_desc: string;
  alarm_detail_desc: string;
  alarm_video_url: string[];
  alarm_picture_url: string[];
}

export class GpsTrajectoryRes {
  data: TrajectoryWithAlarm[];
  status: boolean;
}

export class TrajectoryWithAlarm {
  license_card: string;
  drivers: Array<Driver>;
  time: string;
  speed: number;
  lat: number;
  lon: number;
  direction: number;
  address: string;
  alarm_data: Array<VehicleAlarm>;
}


// 日期选择器 格式
export const TW_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD'
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'YYYY/MM/DD',
    monthYearA11yLabel: 'YYYY MMM'
  }
};
