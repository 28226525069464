/**
 * 车辆轨迹模块
 */
/// <reference path="../../typings/AMap.d.ts" />
import { PlayerControllerComponent } from './components/player-controller/player-controller.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Vehicle, GpsParams, TrajectoryWithAlarm } from './core/vehicle-trajectory.model';
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { SpeedLineComponent } from './components/speed-line/speed-line.component';
import { DetalComponent } from './components/detal/detal.component';
import { from ,  Subject } from 'rxjs';
import { bufferCount, concatMap, switchMap, toArray } from 'rxjs/operators';
import { VehicleTrajectoryService } from './core/vehicle-trajectory.service';

@Component({
  selector: 'app-vehicle-trajectory',
  templateUrl: './vehicle-trajectory.component.html',
  styleUrls: ['./vehicle-trajectory.component.css']
})
export class VehicleTrajectoryComponent implements OnInit {
  // 已经过的点
  line2: AMap.Polyline;
  endTime: Date = new Date();
  startTime: Date = new Date();
  currentVehicle: Vehicle;
  currentLicenceCard: string;
  currentLicenseColor: string;
  errorStatue: string;
  ifShowError: boolean;
  ifShowLoading: boolean;
  content: HTMLElement;
  infoWindow: AMap.InfoWindow;
  // 当前标记的Marker
  currentMarker: AMap.Marker;
  // 标记点
  markerList: AMap.Marker[] = [];
  // 转换后的坐标点
  mapPosititionPoints: AMap.LngLat[] = [];
  // 当前点
  currentPoint = 0;
  // 地图
  map: AMap.Map;
  // 全点位
  lineArry: TrajectoryWithAlarm[] = [];
  // 当前点
  currentGpsTrajectory: TrajectoryWithAlarm = new TrajectoryWithAlarm();
  // 是否显示详情页
  ifShowDetailBox = false;
  ifShowSearchBox = true;
  ifCenter = true;
  @ViewChild('speedLine', { static: true }) speedLine: SpeedLineComponent;
  @ViewChild('detal', { static: false }) detal: DetalComponent;
  @ViewChild('infoWindow', { static: true }) infoWindowContent: ElementRef;
  @ViewChild('controller', { static: true }) controller: PlayerControllerComponent;
  completionRate = 0;
  loadingMsg = '请稍等，正在运输大量数据…';
  constructor(
    private vehicleTrajectoryService: VehicleTrajectoryService,
    private ngZone: NgZone
  ) {
    this.startTime.setDate(new Date().getDate() - 1);
  }

  ngOnInit() {
    this.map = new AMap.Map('map', {
      mapStyle: 'amap://styles/55d876561a1db3c4c8683db556d4f2a0'
    });
  }

  move(point) {
    this.currentPoint = point;
    if (this.infoWindow && this.infoWindow.getIsOpen()) {
      this.infoWindow.close();
    }
    this.setCurrentMarker(point);
  }

  searchGPS(option: { option, vehicle }) {
    this.showLoading(true);
    if (this.map) {
      this.map.clearMap();
      this.currentMarker = undefined;
      this.infoWindow = undefined;
      this.line2 = undefined;
    }
    this.ifShowSearchBox = false;
    this.ifShowDetailBox = false;
    this.currentVehicle = option.vehicle;
    this.currentLicenceCard = this.currentVehicle.licenseCard;
    this.currentLicenseColor = this.currentVehicle.licenseColor;
    this.startTime = option.option.startTime;
    this.endTime = option.option.endTime;
    const options: GpsParams = {
      license_card: this.currentLicenceCard,
      license_color: this.currentLicenseColor,
      start_time: this.startTime,
      end_time: this.endTime
    };

    this.vehicleTrajectoryService.getGpsTrajectoryNew(options).subscribe(
      r => {
        if (r.data.length > 0) {
          this.setGPSPoints(r.data);
          this.currentPoint = 0;
          this.ifShowDetailBox = true;
          setTimeout(() => {
            this.speedLine.setLine(this.lineArry);
          }, 1000);
        } else {
          if (r.status) {
            this.showError('transientError');
          } else {
            this.showError('noData');
          }
        }
      }, (e: HttpErrorResponse) => {
        this.showLoading(false);
        this.showError('serveError');
      }
    );
  }

  setGPSPoints(points: TrajectoryWithAlarm[]) {
    if (points && points.length > 0) {
      this.currentPoint = 0;
      points.forEach(e => {
        e.alarm_data = e.alarm_data.filter(r => r.alarm_type !== 'weather_alarm');
      });
      this.lineArry = points;
      const lngLatList: AMap.LngLat[] = [];
      points.forEach(e => {
        lngLatList.push(new AMap.LngLat(e.lon, e.lat));
      });
      this.coordinateOffset(lngLatList);
    } else {

    }
  }

  // 坐标处理
  coordinateOffset(lngLatList: AMap.LngLat[]) {
    this.loadingMsg = '正在绘制轨迹…';
    let finishedNumber = 0;
    this.completionRate = 0;
    const total = lngLatList.length;
    const dealArray = (v) => {
      const obs = new Subject<AMap.LngLat[]>();
      AMap.convertFrom(v, 'gps', (s, r) => {
        if (r && r.locations) {
          this.ngZone.run(() => {
            finishedNumber += r.locations.length;
            this.completionRate = Math.round(finishedNumber * 100 / total);
            console.log(this.completionRate);
          });
          obs.next(r.locations);
          obs.complete();
        }
      });
      return obs;
    };

    from(lngLatList).pipe(
      bufferCount(40),
      concatMap(v => dealArray(v)),
      switchMap(v => from(v)),
      toArray()
    ).subscribe(v => {
      // console.log('处理GPS点位完成');
      this.mapPosititionPoints = v;
      this.setAlarmMarker(this.lineArry);
      this.setLine(this.mapPosititionPoints);
      this.currentPoint = 0;
      this.setCurrentMarker(this.currentPoint);
    }, e => {
      console.log('getgpsError');
    }, () => {
      console.log('finilize');
      this.ngZone.run(() => {
        this.showLoading(false);
        this.completionRate = 0;
      });
    });
  }

  // 设定违规点
  setAlarmMarker(array) {
    this.mapPosititionPoints.forEach((e, i) => {
      if (array.length > 0) {
        if (array[i].alarm_data.length > 0) {
          if (array[i].alarm_data.length === 1 && array[i].alarm_data[0].alarmType === 'weather_alarm') {
          } else {
            const icon = new AMap.Icon({
              size: new AMap.Size(8, 8),
              image: 'assets/images/icon/Oval2@2x.png',
              imageSize: new AMap.Size(8, 8),
            });
            this.markerList.push(new AMap.Marker({
              map: this.map,
              position: e,
              zIndex: 100,
              icon: icon,
              offset: new AMap.Pixel(-4, -4)
            }));
          }
        }
      }
    });
  }

  // 画线
  setLine(points: AMap.LngLat[]) {
    const lineOption = {
      map: this.map,
      zIndex: 49,
      path: points,
      strokeColor: '#9BB5FF',
      strokeWeight: 8
    };
    const line = new AMap.Polyline(lineOption);
    this.map.setZoom(15);
    this.map.setCenter(points[0]);
  }

  // 已播放的路线
  setLine2(points: AMap.LngLat[]) {
    const lineOption = {
      map: this.map,
      zIndex: 50,
      path: points,
      strokeColor: '#5982f5',
      strokeWeight: 8
    };
    if (this.line2) {
      this.line2.setPath(points);
    } else {
      this.line2 = new AMap.Polyline(lineOption);
    }
  }

  // 设定当前点
  setCurrentMarker(index: number) {
    if (!this.mapPosititionPoints || (this.mapPosititionPoints && this.mapPosititionPoints.length === 0)) { return false; }
    this.ifShowLoading = false;
    if (this.lineArry.length > 0) {
      if (this.currentMarker) {
        this.currentMarker.setPosition(this.mapPosititionPoints[index]);
        this.currentMarker.setAngle(this.lineArry[index].direction);
        let icon: AMap.Icon;
        if (this.lineArry[index].alarm_data.length > 0) {
          if (this.lineArry[index].alarm_data.length === 1 && this.lineArry[index].alarm_data[0].alarm_type === 'weather_alarm') {
            icon = new AMap.Icon({
              size: new AMap.Size(60, 60),
              image: 'assets/images/icon/blue.png',
              imageSize: new AMap.Size(60, 60),
            });
          } else {
            icon = new AMap.Icon({
              size: new AMap.Size(60, 60),
              image: 'assets/images/icon/red.png',
              imageSize: new AMap.Size(60, 60),
            });
          }
        } else {
          icon = new AMap.Icon({
            size: new AMap.Size(60, 60),
            image: 'assets/images/icon/blue.png',
            imageSize: new AMap.Size(60, 60),
          });
        }
        this.currentMarker.setIcon(icon);
        this.currentMarker.setPosition(this.mapPosititionPoints[index]);
        this.currentMarker.setAngle(this.lineArry[index].direction);
      } else {
        let icon: AMap.Icon;
        if (this.lineArry[index].alarm_data.length > 0) {
          if (this.lineArry[index].alarm_data.length === 1 && this.lineArry[index].alarm_data[0].alarm_type === 'weather_alarm') {
            icon = new AMap.Icon({
              size: new AMap.Size(60, 60),
              image: 'assets/images/icon/blue.png',
              imageSize: new AMap.Size(60, 60),
            });
          } else {
            icon = new AMap.Icon({
              size: new AMap.Size(60, 60),
              image: 'assets/images/icon/red.png',
              imageSize: new AMap.Size(60, 60),
            });
          }
        } else {
          icon = new AMap.Icon({
            size: new AMap.Size(60, 60),
            image: 'assets/images/icon/blue.png',
            imageSize: new AMap.Size(60, 60),
          });
        }

        this.currentMarker = new AMap.Marker({
          map: this.map,
          position: this.mapPosititionPoints[index],
          zIndex: 101,
          icon: icon,
          offset: new AMap.Pixel(-30, -30),
          angle: this.lineArry[index].direction
        });
      }
      this.map.setCenter(this.mapPosititionPoints[index]);
      const line2Pionts: AMap.LngLat[] = this.mapPosititionPoints.slice(0, index + 1);
      this.setLine2(line2Pionts);
    }
  }

  setInfoWindow(num) {
    this.controller.setTrace(num);
    this.controller.pause();
    this.currentPoint = num;
    this.setCurrentMarker(num);
    this.content = this.infoWindowContent.nativeElement;
    if (this.lineArry[num].alarm_data.length > 0) {
      if (this.lineArry[num].alarm_data.length === 1 && this.lineArry[num].alarm_data[0].alarm_type === 'weather_alarm') {
        if (this.infoWindow) {
          if (this.infoWindow.getIsOpen()) {
            this.infoWindow.close();
          }
        }
      } else {
        // 是否已生成窗体
        if (this.infoWindow) {
          this.infoWindow.setContent(this.content);
        } else {
          this.infoWindow = new AMap.InfoWindow({
            isCustom: true,  // 使用自定义窗体
            content: this.content,  // 传入 dom 对象，或者 html 字符串
            // offset: new AMap.Pixel(this.content.clientWidth / 2 + 47 , this.content.clientHeight / 2)
            offset: new AMap.Pixel(234, 0),
            size: new AMap.Size(0, 0)
          });
        }
        // 窗体是否打开
        if (this.infoWindow.getIsOpen()) {
          console.log('已打开');
          this.infoWindow.setPosition(this.mapPosititionPoints[num]);
        } else {
          console.log('未打开');
          this.infoWindow.open(this.map, this.mapPosititionPoints[num]);
        }
        const icon = new AMap.Icon({
          size: new AMap.Size(60, 60),
          image: 'assets/images/icon/red.png',
          imageSize: new AMap.Size(60, 60),
        });
        this.currentMarker.setIcon(icon);
        this.ifShowSearchBox = false;
      }
    } else {
      if (this.infoWindow) {
        if (this.infoWindow.getIsOpen()) {
          this.infoWindow.close();
        }
      }
    }
  }
  // 显示loading图标
  showLoading(val) {
    this.ifShowLoading = val;
    this.ifShowSearchBox = false;
    this.loadingMsg = '请稍等，正在运输大量数据…';
  }

  // 显示错误
  showError(statue: string) {
    this.ifShowError = true;
    this.errorStatue = statue;
  }
  // 显示搜索框
  wantSearch() {
    this.ifShowSearchBox = true;
    this.ifCenter = false;
    this.controller.pause();
    if (this.infoWindow && this.infoWindow.getIsOpen()) {
      this.infoWindow.close();
    }
  }
  // 重新搜索
  reSearch() {
    this.ifShowError = false;
    this.errorStatue = '';
    this.ifShowSearchBox = true;
    this.ifShowLoading = false;
    this.loadingMsg = '请稍等，正在运输大量数据…';
    this.ifCenter = true;
    if (this.infoWindow && this.infoWindow.getIsOpen()) {
      this.infoWindow.close();
    }
  }
}

