import { IndexModule } from './index/index.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PublicModule } from './public/public.module';
import { NloginModule } from './nlogin/nlogin.module';
import { nLoginRoutingModule } from './nlogin/nlogin.routing.module';
import { SafetyReportModule } from './safety-report/safety-report.module';
import { VehicleTrajectoryModule } from './vehicle-trajectory/vehicle-trajectory.module';
import { AdasPorjectModule } from './adas-porject/adas-porject.module';
import { AuthInterceptor } from './core/service/auth/auth-interceptor';
import { InsuranceReportModule } from './insurance-report/insurance-report.module';
import { LevelInsuranceReportModule } from './level_insurance-report/level_insurance-report.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule, FormsModule,
    AppRoutingModule, CoreModule.forRoot(),
    PublicModule,
    IndexModule,
    InsuranceReportModule,
    LevelInsuranceReportModule,
    NloginModule,
    nLoginRoutingModule,
    SafetyReportModule,
    VehicleTrajectoryModule,
    AdasPorjectModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
