import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError, Subscription, timer, of } from 'rxjs';
import { catchError, switchMap, map, finalize } from 'rxjs/operators';
import { StorageService } from '../../storage/storage.service';
import { UserService } from '../user.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private Timer: Subscription;

  constructor(
    private storage: StorageService,
    private auth: AuthService,
    private userService: UserService,
    private authService: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url;
    if (url.indexOf('noauth') >= 0) {
      return next.handle(req).pipe(
        finalize(() => {
          this.setLastReqTime();
        })
      );
    }
    document.getElementById('loading').style.display = 'block';
    const jwt = this.authService.getLocalJWT();

    const authReq = req.clone({
      setHeaders: { Authorization: `Bearer ${jwt}` }
    });

    return next.handle(authReq).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 403: {
              if (url.indexOf('noauth') >= 0) {
                break;
              } else {
                return this.storage.getValue<{ k: string; v: string }>('k&v').pipe(
                  switchMap(val => {
                    if (val) {
                      return this.userService.platformUserloginNew(val.k, val.v)
                        .pipe(
                          map(r => {
                            return r;
                          }),
                          catchError((e) => {
                            this.userService.logout();
                            return of(e);
                          }),
                        );
                    } else {
                      this.userService.logout();
                    }
                  }),
                  // tslint:disable-next-line:no-shadowed-variable
                  switchMap((jwt) => {
                    // 使用新jwt
                    const reqWithoutJwt = req.clone({
                      setHeaders: {
                        'Authorization': `Bearer ${jwt}`
                      }
                    });
                    return next.handle(reqWithoutJwt);
                  })
                );
              }
            }
            default:
              return next.handle(authReq);
          }
        } else {
          console.log('error instanceof HttpErrorResponse', error instanceof HttpErrorResponse);
        }
      }),
      finalize(() => {
        document.getElementById('loading').style.display = 'none';
        this.setLastReqTime();
      })
    );
  }
  // 设定最后的请求时间；
  setLastReqTime() {
    const currenttReq = new Date();
    this.storage.saveValue('lastReqTime', currenttReq);
  }

  // 刷新时间
  freshTime() {
    this.clean();
    this.Timer = timer(environment.login_overtime * 60 * 1000).subscribe(r => {
      this.userService.logout();
      this.Timer.unsubscribe();
    });
  }

  clean() {
    if (this.Timer) {
      this.Timer.unsubscribe();
    }
  }
}
