import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Captain } from './models/captain';
import { TelephoneRecord } from './models/telephoneRecord';
import { BindState } from './models/bindState';
import { Alert } from './models/alert';
import { Page } from '../safety/safety.protocol';
import { AuthService } from '../core/service/auth/auth.service';

export interface CaptainsResult {
  totalCount: number;
  data: Captain[];
}

export interface TelephoneRecordsResult {
  totalCount: number;
  data: TelephoneRecord[];
}

export class CaptainDailyParm {
  bind_status: number;
  captain_name: string;
  company_name: string;
  page_number: number;
  page_size: number;
}

export interface CaptainDailyReport {
  captain_id: number;
  captain_name: string;
  telephone: number;
  vehicle_count: number;
  bind_status: number;
  companies: string[];
  score: number;
  selected?: boolean;
}

export interface CaptainDailyReportPageInfo extends Page {
  list: Array<CaptainDailyReport>;
}


@Injectable()
export class AdasService {

  protected baseUrl = environment.newBaseUrl;

  constructor(private httpClient: HttpClient,
    private auth: AuthService) { }

  /**
   * 查询车队长列表
   * @param bindState 绑定状态
   * @param captainName 车队长名
   * @param branchCompanyName 分公司名
   * @param companyName 企业名
   * @param telephone 车队长电话
   * @param offset 跳过开头多少条记录
   * @param limit 每页多少条记录
   */
  getCaptains(bindState?: BindState, captainName?: string,
    branchCompanyName?: string,
    companyName?: string,
    telephone?: string,
    offset = 0,
    limit = 20): Observable<CaptainsResult> {
    let queryParameters = new HttpParams();

    if (bindState !== undefined && bindState !== null) {
      queryParameters = queryParameters.set('bindState', bindState);
    }

    if (captainName !== undefined && captainName !== null) {
      queryParameters = queryParameters.set('captainName', captainName);
    }

    if (companyName !== undefined && companyName !== null) {
      queryParameters = queryParameters.set('companyName', companyName);
    }

    if (branchCompanyName !== undefined && branchCompanyName !== null) {
      queryParameters = queryParameters.set('branchCompanyName', branchCompanyName);
    }

    if (telephone !== undefined && telephone !== null) {
      queryParameters = queryParameters.set('telephone', telephone);
    }

    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    return this.httpClient.get<CaptainsResult>(`${this.baseUrl}/captains`, {
      params: queryParameters
    });
  }


  getCaptainsNew(id: number, params: CaptainDailyParm): Observable<CaptainDailyReportPageInfo> {
    let queryParameters = new HttpParams();

    if (params.bind_status) {
      queryParameters = queryParameters.set("bind_status", <any>(params.bind_status));
    }

    if (params.company_name) {
      queryParameters = queryParameters.set("company_name", <any>(params.company_name));
    }

    if (params.captain_name) {
      queryParameters = queryParameters.set("captain_name", <any>(params.captain_name));
    }

    if (params.page_number) {
      queryParameters = queryParameters.set("page_number", <any>(params.page_number));
    }

    if (params.page_size) {
      queryParameters = queryParameters.set("page_size", <any>(params.page_size));
    }

    let headers = new HttpHeaders();
    const Jwt: string = this.auth.jwt;
    const httpHeaderAccepts: string[] = ["application/json"];
    headers = headers.set("Accept", httpHeaderAccepts);
    headers = headers.set("Authorization", "Bearer " + Jwt);

    let url = `${this.baseUrl}/insurance-orgs/${id}/captains/daily-report/list`;

    return this.httpClient.get<CaptainDailyReportPageInfo>(url, {
      headers: headers,
      params: queryParameters
    })
  }

  /**
   * 获取支公司名列表
   */
  getBranchNames(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.baseUrl}/branches`);
  }



}
