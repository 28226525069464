import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/service/user.service';

@Component({
  selector: 'app-no-pression-page',
  templateUrl: './no-pression-page.component.html',
  styleUrls: ['./no-pression-page.component.css']
})
export class NoPressionPageComponent implements OnInit {

  isHasRoleAndAuthurity:Boolean = true;

  constructor(
    private userService:UserService
  ) {

  }

  ngOnInit() {
  }

  logout() {
    this.userService.logout();
  }

}
