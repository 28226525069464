import { Injectable } from '@angular/core';
import { LoginInfo, InsuranceUser } from '../model/user.model';
import { StorageService } from '../storage/storage.service';
import { Observable, throwError, of } from 'rxjs';
import { HttpService } from '../network/http.service';
import { AppBaseRequest, ReqInsuranceQrcodeLogin } from '../protocol';
import {
  ReqInSuranceLoginToken, ReqTransportLoginToken,
  ReqGetChildCompany, ReqGetWeChatList
} from '../protocol/user.protocol';

import { CookieService } from 'ng2-cookies';
import { map, catchError, switchMap } from 'rxjs/operators';
import { WeChat } from '../model/main.model';
import { AuthService } from './auth/auth.service';
import { environment } from 'src/environments/environment';
import { OrgLastMonthly } from 'src/app/index/index.service';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { InsuranceOrgListSimple } from 'src/app/bind/bind.serevice';
import { Router } from '@angular/router';
import * as moment from 'moment';


@Injectable()
export class UserService {

  readonly baseUrl = environment.newBaseUrl;
  // 机构用户账号相关接口地址
  readonly insuranceAccountUrl = environment.insurAccountBaseUrl;

  private jwt: string;
  _currentUser: InsuranceUser;
  // 登录前的url
  redirectUrl: string;
  readonly saveInsuranceUser = 'insuranceUser';

  public _loginInfo: LoginInfo;

  get currentUser(): Observable<InsuranceUser> | InsuranceUser {
    if (!this._currentUser) {
      return this.storageService.getValue<InsuranceUser>(this.saveInsuranceUser).pipe(
        map(val => {
          if (val) {
            this._currentUser = val;
            return this._currentUser;
          } else {
            return null;
          }
        })
      );
    } else {
      return this._currentUser;
    }
  }

  constructor(
    private storageService: StorageService,
    private httpService: HttpService,
    private cookieService: CookieService,
    private auth: AuthService,
    private httpClient: HttpClient,
    private storage: StorageService,
    private router: Router) {
  }


  /**
   * 保存用户的登录凭证到本地
   */
  saveLoginInfo(info: LoginInfo): Observable<LoginInfo> {
    this.saveLoginUserId(info.user.id);
    return this.storageService.saveItem(this.saveInsuranceUser, info).pipe(
      map(() => info)
    );
  }

  /**
   * 只要登录成功就保存id到cookie
   * @param userId 已登录的用户id
   */
  saveLoginUserId(userId: number) {
    if (userId) {
      this.cookieService.set('user-id', userId + '');
    } else {
      this.cookieService.delete('user-id');
    }
  }

  /**
   * 从cookie中取出登录成功的用户id
   */
  getLoginUserId(): string {
    return this.cookieService.get('user-id');
  }

  /**
   * 删除本地的用户登录凭证
   */
  deleteLoginInfo(): Observable<void> {
    return this.storageService.deleteItem(this.saveInsuranceUser);
  }

  /**
   * 修改平台用户密码
   */
  editPlatformPasswordNew(userId: number, oldPwd: string, newPwd: string): Observable<void> {
    let headers = new HttpHeaders();
    const Jwt: string = this.auth.jwt;
    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);
    headers = headers.set('Authorization', 'Bearer ' + Jwt);

    const url = `${this.insuranceAccountUrl}/insurance/users/${userId}/update-password`;

    return this.httpClient.post<void>(url, { old_password: oldPwd, new_password: newPwd }, {
      headers: headers
    });

  }


  /**
   * 获取保险平台二维码token
   */
  // getInsuranceLoginToken(): Observable<string> {
  //   return this.httpService.request(new ReqInSuranceLoginToken())
  //     .pipe(map(r => r.token));
  // }

  // 机构平台二维码登陆
  getInsuranceWxLoginJwt(uuid: string): Observable<string> {
    const headers = new HttpHeaders();
    const url = `${this.insuranceAccountUrl}/insurance/qrjwt`;
    return this.httpClient.get<{ jwt: string }>(url, {
      params: { uuid: uuid },
      headers: headers,
    }).pipe(
      map(result => {
        this.jwt = result.jwt;
        this.auth.saveLocalJWT(this.jwt);
        return this.jwt;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );

  }

  /**
 * 获取运输平台二维码token
 */
  getTransportLoginToken(): Observable<string> {
    return this.httpService.request(new ReqTransportLoginToken())
      .pipe(map(r => r.token));
  }

  /**
   * 进行保险平台二维码登录
   */
  // isInsuranceQrcodeLogin(token: string): Observable<LoginInfo> {
  //   return this.httpService.request(new ReqInsuranceQrcodeLogin(token)).pipe(
  //     map(req => {
  //       const info = new LoginInfo();
  //       info.user = req.user;
  //       info.expiration = req.expiration;
  //       info.session = req.session;
  //       info.isLoggedIn = true;
  //       return info;
  //     }),
  //     map(info => {
  //       this._loginInfo = info;
  //       AppBaseRequest.session = info.session;

  //       // 保存登录信息
  //       if (info) {
  //         info.isRemember = true;
  //         this.saveLoginInfo(info);
  //       }
  //       return info;
  //     }),
  //     catchError(error => {
  //       throw error;
  //     })
  //   );
  // }


  /**
  * 进行保险平台二维码登录
  */
  // isTransportQrcodeLogin(token: string): Observable<boolean> {
  //   return this.httpService.request(new ReqTransportQrcodeLogin(token)).pipe(map(r => true));
  // }

  /**
   * 进行平台用户登录
   */
  // platformUserlogin(account: string, password: string, saveLogin: boolean = false): Observable<LoginInfo> {
  //   return this.httpService.request(new ReqPlatformUserLogin(account, password)).pipe(map(req => {
  //     const info = new LoginInfo();
  //     info.user = req.user;
  //     info.expiration = req.expiration;
  //     info.session = req.session;
  //     info.isLoggedIn = true;
  //     return info;
  //   })).pipe(
  //     map(info => {
  //       this._loginInfo = info;
  //       AppBaseRequest.session = info.session;

  //       // 保存登录信息
  //       if (info) {
  //         info.isRemember = saveLogin;
  //         this.saveLoginInfo(info);
  //       }
  //       return info;
  //     }),
  //     catchError(error => {
  //       throw error;
  //     })
  //   );
  // }


  platformUserloginNew(username: string, password: string): Observable<string> {
    let headers = new HttpHeaders();
    const idPass = `${username}:${password}`;
    headers = headers.set('Authorization', `Basic ${btoa(idPass)}`);
    const url = `${this.insuranceAccountUrl}/insurance/noauth/login`;
    return this.httpClient.get<{ jwt: string }>(url, {
      headers: headers
    }).pipe(
      map(result => {
        this.jwt = result.jwt;
        this.auth.saveLocalJWT(this.jwt);
        return this.jwt;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }

  // 获取用户的基本信息
  getUserInfo(jwt: string): Observable<InsuranceUser> {
    if (this._currentUser) {
      return of(this._currentUser);
    } else {
      return this.storageService.getValue<InsuranceUser>(this.saveInsuranceUser).pipe(
        map(user => {
          if (user) {
            this._currentUser = user;
            return user;
          } else {
            return null;
          }
        }),
        switchMap(user => {
          console.log('getUserInfo', user);
          if (user) {
            return of(user);
          } else {
            return this.getUserInfoByJwt(jwt);
          }
        }
        )
      );
    }
  }

  getUserInfoByJwt(jwt: string): Observable<InsuranceUser> {
    let headers = new HttpHeaders();
    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);
    headers = headers.set('Authorization', 'Bearer ' + jwt);
    return this.httpClient.get<InsuranceUser>(`${this.insuranceAccountUrl}/insurance/users`, {
      headers: headers
    }).pipe(
      map(r => {
        let isHasheadquarters;
        let headquartersUser: InsuranceUser;
        let noheadquartersUser: InsuranceUser;
        // isHasheadquarters = r.insurance_role_list.map(roleItem => {
        //   return !!roleItem.insurance_authority_list.find(e => e.code === 'b_headquarters');
        // });
        for (const roleItem of r.insurance_role_list) {
          isHasheadquarters = !!roleItem.insurance_authority_list.find(e => e.code === 'b_headquarters');
          if (isHasheadquarters) {break; }
        }
        // console.log('isHasheadquarters', isHasheadquarters);
        if (isHasheadquarters === 'true' || isHasheadquarters === true) {
          headquartersUser = r;
          headquartersUser.org_id = r.insurance_org.children_list[0].id;
          this.storageService.saveValue<InsuranceUser>(this.saveInsuranceUser, headquartersUser);
          this._currentUser = headquartersUser;
          return headquartersUser;
        } else {
          noheadquartersUser = r;
          noheadquartersUser.org_id = r.org_id;
          this.storageService.saveValue<InsuranceUser>(this.saveInsuranceUser, noheadquartersUser);
          this._currentUser = noheadquartersUser;
          return noheadquartersUser;
        }

      })
    );
  }

  /**
   * 用户登出
   */
  logout() {
    this.auth.clear();
    this.storageService.removeValue(this.saveInsuranceUser).pipe(
      switchMap(r => {
        this._currentUser = undefined;
        // console.log('logout', this._currentUser);
        return this.storageService.removeValue('k&v');
      })
    )
      .subscribe(e => {
        this.router.navigate(['/app-insurance-login']);
      });
  }

  /**
   * 获取分公司列表
   */
  getChildCorpList(): Observable<any[]> {
    return this.httpService.request(new ReqGetChildCompany())
      .pipe(
        map(r => r.result)
      );
  }

  /**
   * 获取微信公众号列表
   */
  getWeChatList(): Observable<WeChat[]> {
    return this.httpService.request(new ReqGetWeChatList())
      .pipe(
        map(r => r.weChats)
      );
  }

  /**
   * 获取上月月报状态
   */
  // 获取最后一份月报的月份
  getReportStatusNew(id: number): Observable<string> {

    const Jwt: string = this.auth.jwt;
    let headers = new HttpHeaders();
    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);
    headers = headers.set('authorization', 'Bearer ' + Jwt);
    const url = `${this.baseUrl}/insurance-orgs/${id}/monthly-report/last-month`;
    return this.httpClient.get<{ last_month: string }>(url, {
      headers: headers
    }).pipe(
      map(r => {
        return r.last_month || moment(new Date()).format('YYYY-MM');
      })
    );
  }



  /**
   * 获取当前用户所以公司列表
   */
  getOrgList(): Observable<InsuranceOrgListSimple> {
    const Jwt: string = this.auth.jwt;
    let headers = new HttpHeaders();

    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);
    headers = headers.set('Authorization', 'Bearer ' + Jwt);

    const url = `${this.baseUrl}/insurance-orgs/simple`;

    return this.httpClient.get<InsuranceOrgListSimple>(url, {
      headers: headers
    });
  }



}
