
/**
 * Created by AGUANG on 2017/2/15.
 */
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PublicComponent } from './public.component';
import { AuthGuardService } from '../core/service/auth/auth-guard.service';


@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'main',
        component: PublicComponent,
        canActivate: [AuthGuardService],
        children: [
          {
            path: 'accountList',
            loadChildren: '../account/account.module#AccountModule'
          },
          {
            path: 'bind',
            loadChildren: '../bind/bind.module#BindModule'
          },
          {
            path: 'analysis-report',
            loadChildren: '../analysis-report/analysis-report.module#AnalysisReportModule'
          },
          {
            path: 'rank',
            loadChildren: '../rank/rank.module#RankModule'
          },
          {
            path: 'safety',
            loadChildren: '../safety/safety.module#SafetyModule'
          },
          {
            path: 'record',
            loadChildren: '../record/record.module#RecordModule'
          }
        ]
      }
    ])
  ],
  exports: [RouterModule]
})
export class PublicRoutingModule {

}
