import { InsuranceReportComponent } from './insurance-report.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../core/service/auth/auth-guard.service';


const routes: Routes = [
  {
    path: 'insurance-report/:id/:date/:insurance',
    component: InsuranceReportComponent,
    // canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InsuranceReportRoutingModule { }
