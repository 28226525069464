import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
// <span class="title_font"  [ngClass]="{active:show===1}" (click)="gotoTransport()">运输企业用户 | </span>
@Component({
  selector: 'app-header',
  template: `
      <header>
        <span class="header-logo" (click)="gotoSplash()">甚解风控</span>
        <span class="title_font" [ngClass]="{active:show===2}"(click)="gotoInsurance()">保险机构用户</span>
      </header>
  `,
  styles: [`
  header {
    width: 100%;
    height: 60px;
    min-height: 28px;
    padding-left: 40px;
    display: flex;
    align-items: center;
  }

  header .header-logo {
    font-family: PingFangSC-Semibold;
    font-size: 24px;
    color: #ffffff;
    letter-spacing: 1.5px;
    padding-right: 18px;
    user-select: none;
  }
  .title_font{
    opacity: 0.5;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0.88px;
    cursor: pointer;
  }

  .active {
    opacity: 0.8;
  }
  `
  ]
})
export class HeaderComponent {
  @Input() show: number;
  constructor(protected router: Router) {
  }

  ngOnInit() {
  }

  gotoTransport() {
    this.show = 1;
    this.router.navigate(['app-transport-login']);
  }
  gotoInsurance() {
    this.show = 2;
    this.router.navigate(['app-insurance-login']);
  }
  gotoSplash() {
    this.router.navigate(['app-splash-login']);
  }

}
