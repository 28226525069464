import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TrajectoryWithAlarm } from '../../core/vehicle-trajectory.model';


@Component({
  selector: 'app-detal',
  templateUrl: './detal.component.html',
  styleUrls: ['./detal.component.css']
})

export class DetalComponent implements OnInit {
  _lineArry: TrajectoryWithAlarm[];
  _currentPoint = 0;

  @Input()
  set currentPoint(num: number) {
    this._currentPoint = num;
    if (!this.isShowTableone) {
      this.currentIndex = num;
      const newPage = Math.ceil((num + 1) / this.pageSize);
      if (this.curPage !== newPage) {
        this.getPageData(newPage);
      }
      // this.boxResized();
      setTimeout(() => {
        const newTarget = document.getElementById(num + '');
        if (newTarget) {
          this.boxResized();
          if (!this.isShowTableone) {
            const newTargetone = document.getElementById('overflow_scroll');
            if (this._currentPoint % this.pageSize === 0) {
              if (newTargetone) {
                newTargetone.scrollTop = 0;
              }
            } else {
              newTargetone.scrollTop = newTarget.offsetTop - 200;
            }
          }
        }
      }, 0);
    } else {
      if (this.lineArry && this.lineArry.length > 0) {
        const target: TrajectoryWithAlarm = this.lineArry[num];
        if (target.alarm_data && target.alarm_data.length > 0) {
          const time: string = target.time;
          this.currentAlarmIndex = this.IrregularitiesList.indexOf(this.IrregularitiesList.find(e => e.time === time));
          const newPage = Math.ceil((this.currentAlarmIndex + 1) / this.pageSize);
          if (this.curPage !== newPage) {
            this.getPageData(newPage);
          }
        }
      }
      setTimeout(() => {
        const newTargetgo = document.getElementById(this.currentAlarmIndex + '');
        if (newTargetgo) {
          this.algorithmNumberone();
          const newTargetonego = document.getElementById('overflow_scroll');
          if (this.currentAlarmIndex % this.pageSize === 0) {
            // newTargetgo.scrollIntoView(true);
            if (newTargetonego) {
              newTargetonego.scrollTop = 0;
            }
          } else {
            newTargetonego.scrollTop = newTargetgo.offsetTop - 200;
          }
        }
      }, 0);
    }
  }
  get currentPoint(): number {
    return this._currentPoint;
  }
  @Input()
  set lineArry(array: TrajectoryWithAlarm[]) {
    this._lineArry = array;
  }
  get lineArry(): TrajectoryWithAlarm[] {
    return this._lineArry;
  }
  // tslint:disable-next-line:no-output-on-prefix
  @Output() setCurrentPoint: EventEmitter<number> = new EventEmitter();
  // dataListgo = [];
  page = [];
  arr = [];
  postions = [];
  public totalNum: number;  // 总数据条数
  public pageSize = 1; // 每页数条数
  // public pageData = this.pageSize;  // 每页数据
  // public pageData = 0;
  public totalPage: number; // 总页数
  public curPage = 1; // 当前页码
  active = 1;
  violationsDescone = [];
  activeone = 0;
  isShowone = false;
  textisone = false;
  pageSize1 = 6;
  pageData1 = 6;
  dataList = {};
  isShowTable = false;
  isShowTableone = false;
  IrregularitiesList: TrajectoryWithAlarm[] = [];
  curPage1 = 1; // 当前页码
  currentIndex = 0;
  currentAlarmIndex = 0;
  name = '';
  shouldCount: number;
  constructor() {
    window.addEventListener('resize', () => {
      if (!this.isShowTableone) {
        const newTargetone = document.getElementById('overflow_scroll');
        if (!newTargetone) { return; }
        const itemHeight = 42;
        this.page = this.lineArry;
        this.totalNum = this.page.length;
        this.pageSize = Math.floor(newTargetone.offsetHeight / itemHeight); // 当前浏览器高度总条数
        this.totalPage = Math.floor((this.totalNum - 1) / this.pageSize) + 1; // 总页数
        const newPage = Math.ceil((this.currentIndex + 1) / this.pageSize);
        if (this.curPage !== newPage) {
          this.getPageData(newPage);
        }
      } else {
        const newTargetone = document.getElementById('overflow_scroll');
        const itemHeight = 42;
        this.pageSize = Math.floor(newTargetone.offsetHeight / itemHeight); // 当前浏览器高度总条数
        this.page = this.IrregularitiesList;
        this.totalNum = this.page.length;
        const target: TrajectoryWithAlarm = this.lineArry[this._currentPoint];
        if (target.alarm_data && target.alarm_data.length > 0) {
          const time: string = target.time;
          this.currentAlarmIndex = this.IrregularitiesList.indexOf(this.IrregularitiesList.find(e => e.time === time));
          this.totalPage = Math.floor((this.totalNum - 1) / this.pageSize) + 1; // 总页数
          const newPage = Math.ceil((this.currentAlarmIndex + 1) / this.pageSize);
          if (this.curPage !== newPage) {
            this.getPageData(newPage);
          }
        } else {
          const time: string = target.time;
          this.currentAlarmIndex = this.IrregularitiesList.indexOf(this.IrregularitiesList.find(e => e.time === time));
          this.totalPage = Math.floor((this.totalNum - 1) / this.pageSize) + 1; // 总页数
          const newPage = Math.ceil((this.currentAlarmIndex + 1) / this.pageSize);
          if (this.curPage > this.totalPage) {
            this.getPageData(1);
          }
        }
      }
    });
  }

  boxResized() {
    const newTargetone = document.getElementById('overflow_scroll');
    const itemHeight = 42;
    this.pageSize = Math.floor(newTargetone.offsetHeight / itemHeight); // 当前浏览器高度总条数
    this.page = this.lineArry;
    this.totalNum = this.page.length;
    this.totalPage = Math.floor((this.totalNum - 1) / this.pageSize) + 1; // 总页数
  }

  algorithmNumberone() {
    const newTargetone = document.getElementById('overflow_scroll');
    const itemHeight = 42;
    this.pageSize = Math.floor(newTargetone.offsetHeight / itemHeight); // 当前浏览器高度总条数
    this.page = this.IrregularitiesList;
    this.totalNum = this.page.length;
    this.totalPage = Math.floor((this.totalNum - 1) / this.pageSize) + 1; // 总页数
  }
  getPageData(pageNo) {
    this.curPage = pageNo;
  }
  ngOnInit() {

    this.lineArry.forEach(e => {
      if (e.drivers) {
        e.drivers.forEach((k, l) => {
          if (l === 0) {
            this.name = k.name;
          } else {
            this.name = this.name + ',' + k.name;
          }
        });
      }
    });
  }
  ngAfterViewInt() {
    this.boxResized();
  }

  // 违规查询
  illegaLinquiries() {
    this.IrregularitiesList = [];
    this.lineArry.forEach(e => {
      if (e.alarm_data) {
        if (e.alarm_data.length > 0) {
          if (e.alarm_data.length === 1 && e.alarm_data[0].alarm_type === 'weather_alarm') {

          } else {
            this.IrregularitiesList.push(e);
          }
        }
      }
    });
    if (this.lineArry && this.lineArry.length > 0) {
      const time = this.lineArry[this.currentIndex].time;
      const target = this.IrregularitiesList.find(e => e.time === time);
      if (target) {
        this.currentAlarmIndex = this.IrregularitiesList.indexOf(target);
        const newPage = Math.ceil((this.currentAlarmIndex + 1) / this.pageSize);
        if (this.curPage !== newPage) {
          this.getPageData(newPage);
        }
      } else {
        this.currentAlarmIndex = undefined;
      }
    }
  }

  setActive(value: number) {
    if (value === 0) {
      // 全部
      this.active = 1;
      this.isShowTable = false;
      this.isShowTableone = false;
      this.boxResized();
      const newPage = Math.ceil((this.currentIndex + 1) / this.pageSize);
      if (this.curPage !== newPage) {
        this.getPageData(newPage);
      }
    } else {
      // 仅违规
      this.isShowTable = true;
      this.isShowTableone = true;
      this.illegaLinquiries();
      this.algorithmNumberone();
      this.active = 0;

      if (this.curPage > this.totalPage) {
        this.curPage = this.totalPage;
      }
    }
  }
  onClickData(date: string) {
    const target = this.lineArry.find(e => e.time === date);
    this.currentIndex = this.lineArry.indexOf(target);
    this.setCurrentPoint.emit(this.currentIndex);
  }
}
