import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { HttpService } from './network/http.service';
import { UserService } from './service/user.service';
import { StorageService } from './storage/storage.service';
import { CookieService } from 'ng2-cookies';

import { ExcelService } from './service/excel.service';
import { AuthGuardService } from './service/auth/auth-guard.service';

@NgModule({
  imports: [],
  declarations: [],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        StorageService,
        HttpService,
        UserService,
        AuthGuardService,
        CookieService,
        ExcelService
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
