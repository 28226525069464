import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashLoginComponent } from './splash-login/splash-login.component';
import { InsuranceLoginComponent } from './insurance-login/insurance-login.component';
import { QRCodeModule } from 'angular2-qrcode';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';

@NgModule({
  imports: [
    CommonModule,
    QRCodeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [SplashLoginComponent, InsuranceLoginComponent, HeaderComponent],
  exports: [SplashLoginComponent, InsuranceLoginComponent]
})
export class NloginModule { }
