import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-splash-login',
  templateUrl: './splash-login.component.html',
  styleUrls: ['./splash-login.component.css']
})
export class SplashLoginComponent implements OnInit  {


  constructor(protected router: Router) {

   }

  ngOnInit() {
  }

  OnClick(data) {
    if (data === true) {
      this.router.navigate(['/app-insurance-login']);
    } else {
      this.router.navigate(['/app-transport-login']);
    }
  }

}
