import { LevelInsuranceReportComponent} from './level_insurance-report.component'
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../core/service/auth/auth-guard.service';


const routes: Routes = [
  {
    path: 'insurance-report-level3/:id/:date/:insurance',
    component: LevelInsuranceReportComponent,
    // canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LevelInsuranceReportRoutingModule { }
