/**
 * Created by AGUANG on 2017/2/15.
 */
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SplashLoginComponent } from './splash-login/splash-login.component';
import { InsuranceLoginComponent } from './insurance-login/insurance-login.component';


@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'app-insurance-login',
      },
      {
        path: 'app-splash-login',
        component: SplashLoginComponent
      },
      {
        path: 'app-insurance-login',
        component: InsuranceLoginComponent
      }
    ])
  ],
  exports: [RouterModule]
})
export class nLoginRoutingModule { }
