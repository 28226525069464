import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafetyReportRoutingModule } from './safety-report-routing.module';
import { VehicleSafetyReportComponent } from './vehicle-safety-report/vehicle-safety-report.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SafetyReportRoutingModule,
    SharedModule
  ],
  declarations: [VehicleSafetyReportComponent]
})
export class SafetyReportModule { }
