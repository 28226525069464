import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Directive({
  selector: '[scrollBottomDetect]',
  exportAs: 'scrollBottomDetect'
})
export class ScrollBottomDetectDirective {

  constructor(private el: ElementRef) {
    this.subScribe.pipe(debounceTime(300)).subscribe( val => {
      this.reachBottom.emit(val);
    });
  }

  subScribe: Subject<boolean> = new Subject<boolean>();

  @Output() reachBottom = new EventEmitter();

  @Output() backIconEvent = new EventEmitter();

  @HostListener('scroll', ['$event'])
  onScroll() {

    // 元素总高度
    const wholeHeight = this.el.nativeElement.scrollHeight;

    // 元素距离顶部高度
    const scrollTop = this.el.nativeElement.scrollTop;

    // 元素可视区域高度
    const divHeight = this.el.nativeElement.clientHeight;

    const documentHeight = document.documentElement.clientHeight;

    if ( Math.ceil(scrollTop) + Math.ceil(divHeight) >= Math.ceil(wholeHeight)) {
      this.subScribe.next(true);
    }

    if ( Math.ceil(scrollTop) > Math.ceil(documentHeight)) {
      this.backIconEvent.emit(true);
    } else {
      this.backIconEvent.emit(false);
    }

  }

}
