import { Jsonify } from '../core/model/jsonify.interface';
import { AppBaseRequest } from '../core/protocol';

// 获取数据协议
export class ReqIndexOption extends AppBaseRequest {

  option: IndexOption = new IndexOption();

  constructor(private id: number, private date: number) {
    super();
  }

  protected getParameterUrl() {
    return 'wx/vehicle/order/getCustomerIndexInfo';
  }

  protected makeRequestBody(): any {
    return {
      company_id: this.id,
      date: this.date
    };
  }

  protected dealJsonResult(json: any): boolean {
    if (json) {
      this.option = new IndexOption().parse(json);
    }
    return true;
  }
}

// 参数对象
export class IndexOption implements Jsonify<IndexOption> {
  // 托管车辆数
  escrowVehicleNumber: string;
  // 绑定用户数
  bindUserNumber;
  // 用户总数
  userNumber;
  // 疲劳数
  fatigue;
  // 恶劣天气数
  badWeather;
  // 超速数
  seriousOverspeed;
  // 危险路段数
  dangerWay;
  // 设备故障数
  equipFail;
  // 安全视频数
  media;
  // 安全报告数
  dailySummary;
  // 消息广播数
  bindRemind;
  constructor() {}
  parse(json: any): IndexOption {
    this.escrowVehicleNumber = json['escrow_vehicle_number'];
    this.bindUserNumber = json['bind_user_number'];
    this.userNumber = json['user_number'];
    this.fatigue = json['fatigue'];
    this.badWeather = json['bad_weather'];
    this.seriousOverspeed = json['serious_overspeed'];
    this.dangerWay = json['danger_way'];
    this.equipFail = json['equip_fail'];
    this.media = json['media'];
    this.dailySummary = json['daily_summary'];
    this.bindRemind = json['bind_remind'];
    return this;
  }

  toJsonObject(): any {
    const json = {
      escrow_vehicle_number: this.escrowVehicleNumber,
      bind_user_number: this.bindUserNumber,
      user_number: this.userNumber,
      fatigue: this.fatigue,
      bad_weather: this.badWeather,
      danger_way: this.dangerWay,
      serious_overspeed: this.seriousOverspeed,
      equip_fail: this.equipFail,
      media: this.media,
      daily_summary: this.dailySummary,
      bind_remind: this.bindRemind
    };
    return json;
  }
}

