import { Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: '[scrollToTop]',
  exportAs: 'scrollToTop'
})
export class ScrollToTopDirective {

  constructor(private el: ElementRef) { }

  toTop() {
    // 元素回到顶部
    this.el.nativeElement.scrollTop = 0;
  }
}
