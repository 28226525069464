import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-my-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class MyPaginatorComponent implements OnInit {
  _offSet = 0;
  _limit = 0;
  _total = 0;
  _currentPage = 1;
  _totalPage = 1;
  showLabel = true;
  startNumber = 0;
  endNumber = 0;

  @Output() changePage = new EventEmitter();

  constructor(
  ) { }

  ngOnInit() {
  }

  focus() {
    this.showLabel = false;
    document.getElementById('pageInput').focus();
  }
  // 跳转
  jump(num) {
    if (num > this._totalPage) {
      num = this._totalPage;
    } else if (num <= 0 ) {
      num = 1;
    }
    this.showLabel = true;
    this.changePage.emit(num - 1);
  }
  /**
   *
   * @param total 总数
   * @param offSet 偏移
   * @param limit 限制
   */
  setPage(total:number, offSet:number, limit:number) {
    this._total = total;
    this._offSet = offSet;
    this._limit = limit;
    if (total === 0) {
      this.endNumber = 0;
      this.startNumber = 0;
      this._totalPage = 1;
      this._currentPage = 1;
    } else {
      this.startNumber = offSet + 1;
      this.endNumber = offSet + limit;
      if (this.endNumber > total) {
        this.endNumber = total;
      }
      this._totalPage = Math.ceil(total / limit);
      this._currentPage = Math.ceil(offSet / limit) + 1;
    }
    this.showLabel = true;
  }
}
