import { Component, ElementRef, HostListener, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import * as echarts from 'echarts';
export class FatigueTimesData {
  fatigue_count_avg: number[] = [];
  pre_fatigue_count_avg: number[] = [];
  country_fatigue_count_avg: number[] = [];
}
@Component({
  selector: 'app-fatigue-times',
  templateUrl: './fatigue-times.component.html',
  styleUrls: ['./fatigue-times.component.css']
})
export class FatigueTimesComponent implements OnInit, AfterViewInit {
  title = '车均疲劳次数';
  @ViewChild('chart', { static: true }) _chart: ElementRef;
  chart: echarts.ECharts;
  remark = '';
  onResize() {
    if (this.chart) { this.chart.resize(); }
  }
  // tslint:disable-next-line:member-ordering
  @Input() date = new Date();
  // tslint:disable-next-line:member-ordering
  _data: FatigueTimesData = new FatigueTimesData;
  @Input()
  set data(data: FatigueTimesData) {
    this._data = data;
    if (data) {
      this.setChart(data);
      this.setRemark(data);
    }
  }
  get data(): FatigueTimesData {
    return this._data;
  }
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.chart = echarts.init(this._chart.nativeElement);
  }
  setRemark(data: FatigueTimesData) {
    const month = this.date.getMonth();
    const currentValue = data.fatigue_count_avg[month] || 0;
    const lastValue = data.pre_fatigue_count_avg[month];
    const growthRate = Math.round((currentValue - lastValue) / lastValue * 1000) / 10;
    this.remark = `托管车辆车均疲劳次数${currentValue}`;
    this.remark += (data.country_fatigue_count_avg[month] && data.country_fatigue_count_avg[month] !== currentValue) ?
      (data.country_fatigue_count_avg[month] > currentValue
        ? `低于全国均值${data.country_fatigue_count_avg[month]}`
        : `，高于全国均值${data.country_fatigue_count_avg[month]}`) : '';
    this.remark += lastValue && growthRate ?
      (growthRate > 0 ? `，与${this.date.getFullYear() - 1}年同期相比上升了${Math.abs(growthRate)}%。`
        : `，与${this.date.getFullYear() - 1}年同期相比下降了${Math.abs(growthRate)}%。`)
      : '。';
  }
  setChart(data: FatigueTimesData) {
    this.chart.resize();
    const option = {
      legend: {
        data: [`${this.date.getFullYear() - 1}年托管车均疲劳次数`, `${this.date.getFullYear()}年托管车均疲劳次数`]
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '15px',
        right: '15px',
        bottom: '15px',
        top: '40px',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
        }
      ],
      series: [
        {
          name: `${this.date.getFullYear() - 1}年托管车均疲劳次数`,
          type: 'line',
          symbol: 'circle',
          itemStyle: {
            color: '#32C5FF'
          },
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.pre_fatigue_count_avg
        },
        {
          name: `${this.date.getFullYear()}年托管车均疲劳次数`,
          type: 'line',
          symbol: 'circle',
          itemStyle: {
            color: '#B620E0'
          },
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.fatigue_count_avg
        }
      ]
    };
    this.chart.setOption(option);
  }
}
