import { Component, ElementRef, HostListener, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import * as echarts from 'echarts';
import * as moment from 'moment';
export class FatigueData {
  fatigue_timelength_hour_avg: number[] = [];
  org_fatigue_timelength_rate_avg: number[] = [];
  pre_fatigue_timelength_hour_avg: number[] = [];
  pre_org_fatigue_timelength_rate_avg: number[] = [];
  country_org_fatigue_timelength_rate_avg: number[] = [];
}
@Component({
  selector: 'app-fatigue',
  templateUrl: './fatigue.component.html',
  styleUrls: ['./fatigue.component.css']
})
export class FatigueComponent implements OnInit, AfterViewInit {
  title = '车均疲劳时长、疲劳时长占比';
  @ViewChild('chart', { static: true }) _chart: ElementRef;
  chart: echarts.ECharts;
  remark: string;
  onResize() {
    if (this.chart) { this.chart.resize(); }
  }
  // tslint:disable-next-line:member-ordering
  @Input() date = new Date();
  // tslint:disable-next-line:member-ordering
  @Input() insurance = '';
  // tslint:disable-next-line:member-ordering
  _data: FatigueData = new FatigueData;
  @Input()
  set data(data: FatigueData) {
    this._data = data;
    if (data) {
      this.setChart(data);
      this.setRemark(data);
    }
  }
  get data(): FatigueData {
    return this._data;
  }
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.chart = echarts.init(this._chart.nativeElement);
  }
  setRemark(data: FatigueData) {
    const month = this.date.getMonth();
    const currenthour = data.fatigue_timelength_hour_avg[month] || 0;
    const currentRate = data.org_fatigue_timelength_rate_avg[month] || 0;
    let lastHour = 0;
    if (month === 0) {
      lastHour = data.pre_fatigue_timelength_hour_avg[11] || 0;
    } else {
      lastHour = data.pre_fatigue_timelength_hour_avg[month] || 0;
    }
    const growthRate = Math.round((currenthour - lastHour) / lastHour * 1000) / 10;
    // tslint:disable-next-line:max-line-length
    this.remark = `${this.insurance}${moment(this.date).format('YYYY年M月')}托管车辆车均疲劳时长${currenthour}小时，`;
    // tslint:disable-next-line:max-line-length
    this.remark += growthRate && lastHour ? `同比${growthRate > 0 ? '上升' : '下降'}${Math.abs(growthRate)}%，疲劳时长占比${currentRate}%` : `疲劳时长占比${currentRate}%`;
    // tslint:disable-next-line:max-line-length
    this.remark += (data.country_org_fatigue_timelength_rate_avg[month] && data.country_org_fatigue_timelength_rate_avg[month] < currentRate) ?
      `，需重点监控疲劳问题。` : '。';
    // this.remark += data.country_org_fatigue_timelength_rate_avg[month];
  }
  setChart(data: FatigueData) {
    this.chart.resize();
    const option = {
      legend: {
        data: [`${this.date.getFullYear() - 1}年车均疲劳时长`, `${this.date.getFullYear()}年车均疲劳时长`,
        `${this.date.getFullYear() - 1}年疲劳时长占比`, `${this.date.getFullYear()}年疲劳时长占比`]
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '15px',
        right: '15px',
        bottom: '15px',
        top: '40px',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '单位(小时)',
          axisLabel: {
            formatter: '{value}'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
        },
        {
          type: 'value',
          name: '上线率(%)',
          min: 0,
          max: 100,
          interval: 10,
          axisLabel: {
            formatter: '{value}%'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          splitNumber: 5,
        }
      ],
      series: [
        {
          name: `${this.date.getFullYear() - 1}年车均疲劳时长`,
          type: 'bar',
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#27BBFF' // 0% 处的颜色
              }, {
                offset: 0.5, color: '#6361FF' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          label: {
            show: true,
            position: 'top'
          },
          data: data.pre_fatigue_timelength_hour_avg
        },
        {
          name: `${this.date.getFullYear()}年车均疲劳时长`,
          type: 'bar',
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#FFA327' // 0% 处的颜色
              }, {
                offset: 0.5, color: '#FF4D28' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          label: {
            show: true,
            position: 'top'
          },
          data: data.fatigue_timelength_hour_avg
        },
        {
          name: `${this.date.getFullYear() - 1}年疲劳时长占比`,
          type: 'line',
          symbol: 'circle',
          itemStyle: {
            color: '#32C5FF'
          },
          yAxisIndex: 1,
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.pre_org_fatigue_timelength_rate_avg
        },
        {
          name: `${this.date.getFullYear()}年疲劳时长占比`,
          type: 'line',
          symbol: 'circle',
          itemStyle: {
            color: '#B620E0'
          },
          yAxisIndex: 1,
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.org_fatigue_timelength_rate_avg
        }
      ]
    };
    this.chart.setOption(option);
  }
}
