import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { VehicleAlarm } from '../../core/vehicle-trajectory.model';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  _alarmData: VehicleAlarm;
  // tslint:disable-next-line:no-inferrable-types
  currentUrl: string = '';
  ifImage: boolean;
  videoList: string[] = [];
  // tslint:disable-next-line:max-line-length
  imageList: string[] = [];
  currentIndex = 0;
  @ViewChild('images', { static: true }) images: ElementRef;
  @Input()
    set alarmData(data: VehicleAlarm) {
      this.dealAlarmData(data);
      this._alarmData = data;
    }
    get alarmData(): VehicleAlarm {
      return this._alarmData;
    }
  constructor() { }

  ngOnInit() {

  }

  scrollFor(num: number) {
    const currentScroll: number = this.images.nativeElement.scrollLeft;
    this.images.nativeElement.scrollLeft = currentScroll + num;
  }
  setCurrentImg(index: number, ifImage: boolean, url: string) {
    this.ifImage = ifImage;
    this.currentIndex = index;
    this.images.nativeElement.scrollLeft = (index - 1) * 50 - 17;
    this.currentUrl = url;
  }

  dealAlarmData(alarmData: VehicleAlarm) {
    this.videoList = [];
    this.imageList = [];
    alarmData.alarm_video_url.forEach(v => {
      this.videoList.push(v);
    });
    alarmData.alarm_picture_url.forEach(img => {
      this.imageList.push(img);
    });
    this.ifImage = this.videoList.length === 0;
    if (this.videoList.length > 0) {
      // this.ifImage = false;
      this.currentUrl = this.videoList[0];
    } else if (this.imageList.length > 0) {
      // this.ifImage = true;
      this.currentUrl = this.imageList[0];
    } else {
      // this.ifImage = true;
      this.currentUrl = 'assets/images/Group11@2x.png';
      this.imageList.push(this.currentUrl);
    }
    // for (let i = 0 ; i <= 100 ; i++) {
    //   this.imageList.push('assets/images/Group11@2x.png');
    // }
  }
}
