import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, of, TimeoutError } from 'rxjs';
import { catchError, finalize, timeout, retry, map } from 'rxjs/operators';
import { BaseRequest } from '../protocol/base.protocol';

export class LogicError extends Error {

}

/**
 * 负责请求、响应所有的业务HTTP协议
 */
@Injectable()
export class HttpService {

  /**
   * 是否执行请求调用前的方法
   */
  static skipMethod = false;

  /**
   *  登录session，必须在登录后立刻设置，登出后设为null
   */
  session: string;

  /**
   * 每个请求前调用的方法
   */
  beforeRequests: (() => void)[] = [];

  /**
   * 每个请求结束后调用的方法
   */
  afterRequests: (() => void)[] = [];


  get onResultStatus(): Subject<string> {
    return this._resultSubject;
  }

  private _resultSubject: Subject<string>;

  constructor(private httpClient: HttpClient) {
    this._resultSubject = new Subject<string>();
  }

  request<T extends BaseRequest>(protocol: T): Observable<T> {

    if (!HttpService.skipMethod) {
      for (const func of this.beforeRequests) {
        try {
          func();
        } catch (error) {
          console.error(`HttpService#request beforeRequests error:${error}`);
        }
      }
    }

    const req = protocol.makeRequest();
    return this.httpClient.request(req.method, req.urlWithParams, {
      body: req.body,
      headers: req.headers,
      responseType: req.responseType,
      reportProgress: req.reportProgress,
      observe: 'response'
    }).pipe(
      timeout(protocol.timeout),
/*      retry(protocol.retryTimes),*/
      map((event: HttpResponse<any>) => {
        protocol.dealResponse(event);
        return protocol;
      }),
      finalize(() => {
        for (const func of this.afterRequests) {
          try {
            func();
          } catch (error) {
            console.error(`HttpService#request afterRequests error:${error}`);
          }
        }
      }),
      catchError((err: HttpErrorResponse | LogicError) => {
        let errorMsg = '';
        if (err instanceof LogicError) {
          errorMsg = err.message;
        } else if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('An http error occurred:', err.error.message);
          errorMsg = err.error.message;
        } else if (err.status === 0) {
          errorMsg = 'cors';
        } else if (err instanceof TimeoutError) {
          errorMsg = 'timeout';
        } else if (err instanceof Error) {
          console.log(`An error occurred: ${err.message}`);
          errorMsg = err.message;
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.log(`Backend returned code ${err.status}, body was: ${err.error}, error:${JSON.stringify(err.error)}`);
          errorMsg = err.status.toString();
        }

        // 广播resultStatus
        this.brodcast(errorMsg);
        throw errorMsg;
        // return new ErrorObservable(errorMsg);
      })
    );
  }


  private brodcast(status: string) {
    this._resultSubject.next(status);
  }
}
