import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  public get jwt(): string {
    if (!this._jwt) {
      this._jwt = this.getLocalJWT();
    }
    return this._jwt;
  }

  private _jwt: string;

  constructor() {
  }

  clear() {
    localStorage.removeItem('jwt');
    this._jwt = undefined;
  }


  isLoggedIn(): boolean {
    return this.jwt && this.canDecryptJwt(this.jwt);
  }


  getLocalJWT(): string {
    const jwt = localStorage.getItem('jwt');
    this._jwt = jwt;
    return jwt;
  }


  saveLocalJWT(jwt: string) {
    localStorage.setItem('jwt', jwt);
    this.canDecryptJwt(jwt);
    this._jwt = jwt;
  }
   /**
   * jwt是否能正确解码
   * @param jwt
   */
  canDecryptJwt(jwt: string): boolean {
    try {
      const json = jwt_decode(jwt);
      return true;
    } catch (error) {
      console.error(`jwt解码失败:${error}`);
      return false;
    }
  }


/**
   * 从当前jwt获取用户id
   */
  getUserId(): number {
    try {
      const jwt = this.jwt;
      const json = jwt_decode(jwt);
      const userid = parseInt(decodeURI(json.sub), 10);
      return userid;
    } catch (error) {
      console.error(`jwt解码失败:${error}`);
      return null;
    }
  }


  /**
   * 从当前jwt获取当前用户的type
   */
  getUserType(): number {
    try {
      const jwt = this.jwt;
      const json = jwt_decode(jwt);
      const type = json.type;
      return type;
    } catch (error) {
      console.error(`jwt解码失败:${error}`);
      return null;
    }
  }

}
