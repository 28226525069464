import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InsuranceUser } from 'src/app/core/model/user.model';
import { UserService } from 'src/app/core/service/user.service';
import { map } from 'rxjs/operators';

@Injectable()

export class PermissionService {

  private currentUser: InsuranceUser;
  authCodeTree: string[] = [];
  // 权限code 列表
  permission = [
    // 总公司
    {
      code: 'b_headquarters',
      url: '/index'
    },
    // 首页
    {
      code: 'm_dashboard',
      url: '/index'
    },
    // 轨迹回放
    {
      code: 'b_trajectory',
      url: '/index'
    },
    // 托管情况
    {
      code: 'm_trusteeship',
      url: '/main/accountList'
    },
    // 托管-->车辆托管情况
    {
      code: 'm1_vehicle_trusteeship',
      url: '/main/accountList'
    },
    // 托管-->公众号绑定情况(我是团队长)
    {
      code: 'm1_wechat_colonel',
      url: '/main/bind/leaderList'
    },
    // 托管-->公众号绑定情况(我是车队长)
    {
      code: 'm1_wechat_captain',
      url: '/main/bind/captainList'
    },
    // 月度分析
    {
      code: 'm_monthly_analysis',
      url: '/main/analysis-report'
    },
    // 月度分析-->风控分析报告
    {
      code: 'm1_monthly_report',
      url: '/main/analysis-report'
    },
    // 月度分析-->车辆安全情况
    {
      code: 'm1_safe_info',
      url: '/main/safety'
    },
    // 月度分析--> 企业排名
    {
      code: 'm1_company_rank',
      url: '/main/rank'
    },
    // 每日报告
    {
      code: 'm_daily_report',
      url: '/adas'
    }
  ];
  constructor(private userService: UserService) {
  }

  getAuthTree(authList: any) {
    authList.forEach(r => {
      this.authCodeTree.push(r.code);
      // console.log('*****from user get authCodeTree*********', this.authCodeTree);
      if (r.children_list && r.children_list.length > 0) {
        this.getAuthTree(r.children_list);
      }
    });
  }


  canInit(url): boolean | Observable<boolean> {
    if (this.currentUser) {
      return this.test(url);
    } else {
      if (this.userService.currentUser instanceof Observable) {
        return this.userService.currentUser.pipe(
          map(r => {
            if (!r) {
              this.userService.logout();
            }
            this.currentUser = r;
            this.authCodeTree = [];
            console.log('permission observable');
            if (r.insurance_role_list) {
              r.insurance_role_list.forEach(e => {
                if (e.insurance_authority_list) {
                  this.getAuthTree(e.insurance_authority_list);
                }
              });
            }
            return this.test(url);
          })
        );
      } else if (this.userService.currentUser instanceof Object) {
        this.currentUser = this.userService.currentUser;
        this.authCodeTree = [];
        console.log('permission user');
        if (this.currentUser.insurance_role_list) {
          this.currentUser.insurance_role_list.forEach(e => {
            if (e.insurance_authority_list) {
              this.getAuthTree(e.insurance_authority_list);
            }
          });
        }
        return this.test(url);
      }
    }
  }

  test(url): boolean {
    const target: { code: string, url: string } = this.permission.find(r => r.url === url);
    if (!target) {
      return true;
    } else {
      if (this.currentUser) {
        return this.authCodeTree.indexOf(target.code) >= 0;
      } else {
        return false;
      }
    }
  }
}
