// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { EnvConfig } from './environment.interface';

export const environment: EnvConfig = {
  production: false,
  name: 'dev',
  newBaseUrl: '/api',
  insurAccountBaseUrl: '/base-api',
  PdfUrl: '/export-service-url',

  dataCenterWebUrl: 'https://datacentertest.sjfkpt.com',
  wxCaptainUrl: 'https://wx-captain-test.sjfkpt.com/',
  // 新增微信登陆
  salesmanWxLoginUrl: 'https://wx-salesman-test.sjfkpt.com/wx-login',
  login_overtime: 60
};
