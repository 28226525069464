import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleTrajectoryRoutingModule } from './vehicle-trajectory-routing.module';
import { SearchComponent } from './components/search/search.component';
import { SharedModule } from '../shared/shared.module';
import { TooltipModule } from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DetalComponent } from './components/detal/detal.component';
import { PlayerControllerComponent } from './components/player-controller/player-controller.component';
import { SpeedLineComponent } from './components/speed-line/speed-line.component';
import { VehicleTrajectoryService } from './core/vehicle-trajectory.service';
import { CardsComponent } from './components/cards/cards.component';
import { SliderComponent } from './components/slider/slider.component';
import { TW_FORMATS } from './core/vehicle-trajectory.model';
import { UserService } from '../core/service/user.service';
import { VehicleTrajectoryComponent } from './vehicle-trajectory.component';

@NgModule({
  imports: [
    CommonModule,
    VehicleTrajectoryRoutingModule,
    SharedModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatSliderModule,
    MatTabsModule
  ],
  providers: [
    VehicleTrajectoryService,
    UserService,
    { provide: MAT_DATE_LOCALE, useValue: 'zh-TW' },
    { provide: MAT_DATE_FORMATS, useValue: TW_FORMATS }
  ],
  declarations: [
    VehicleTrajectoryComponent,
    SearchComponent,
    DetalComponent,
    PlayerControllerComponent,
    SpeedLineComponent,
    CardsComponent,
    SliderComponent
  ],
  exports: [
    VehicleTrajectoryComponent
  ]
})
export class VehicleTrajectoryModule { }
