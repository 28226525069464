import { ErrorService } from 'src/app/ErrorService/error.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BasePage } from '../core/base-page/base-page';
import { saveAs } from 'file-saver/FileSaver';
import { Company, InsuranceReportService, PdfResult } from './insurance-report.service';
import { VehicleOnlineData } from './component/statistics/vehicle-online/vehicle-online.component';
import { WxBindingData } from './component/statistics/wx-binding/wx-binding.component';
import { FatigueData } from './component/analysis/fatigue/fatigue.component';
import { FatigueTimesData } from './component/analysis/fatigue-times/fatigue-times.component';
import { ManualReminderData } from './component/analysis/manual-reminder/manual-reminder.component';
import { MilesTimelengthData } from './component/analysis/miles-and-timelength/miles-and-timelength.component';
import { OverSpeedTimesData } from './component/analysis/over-speed-times/over-speed-times.component';
import { PercentageMileageData } from './component/analysis/percentage-mileage/percentage-mileage.component';
import { PercentageOverSpeedData } from './component/analysis/percentage-over-speed/percentage-over-speed.component';
import { PercentageRoadData } from './component/analysis/percentage-road/percentage-road.component';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

declare const window: any;

@Component({
  selector: 'app-insurance-report',
  templateUrl: './insurance-report.component.html',
  styleUrls: ['./insurance-report.component.css']
})
export class InsuranceReportComponent extends BasePage implements OnInit, AfterViewInit {
  currentId: number;
  currentDate: Date = new Date();
  currentInsurance = '';
  currentComment = '';
  isPdfMode = false;
  vehicleOnlineData: VehicleOnlineData;
  wxBindingData: WxBindingData;
  fatigueData: FatigueData;
  fatigueTimesData: FatigueTimesData;
  manualReminderData: ManualReminderData;
  milesTimelengthData: MilesTimelengthData;
  overSpeedTimesData: OverSpeedTimesData;
  percentageMileageData: PercentageMileageData;
  percentageOverSpeedData: PercentageOverSpeedData;
  percentageRoadData: PercentageRoadData;
  rank: Company[] = [];
  pdfWidth = '1200px';
  constructor(
    private api: InsuranceReportService,
    private router: ActivatedRoute,
    private error: ErrorService
  ) {
    super();
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.router.params.subscribe(r => {
      this.currentId = r.id;
      this.currentInsurance = r.insurance;
      this.currentDate = new Date(r.date);
      this.fresh();
    });
  }
  hasComment() {
    //白字符  比如 空格 回车 
    if (this.currentComment && this.currentComment.trim().length > 0) {
      return true;
    }
    return false;
  }
  fresh() {
    const month = this.currentDate.getMonth() + 1;
    const option = {
      org_id: this.currentId,
      month: this.currentDate.getFullYear() + '-' + (month < 10 ? ('0' + month) : month)
    };
    // this.showLoading();
    this.api.getData(option).subscribe(r => {
      let comment = r[month - 1]['comment'];
      // console.log(comment)
      this.currentComment = comment
      this.getCompanyRank();
      // this.rank = [new Company()];
      this.vehicleOnlineData = new VehicleOnlineData();
      this.wxBindingData = new WxBindingData();
      this.fatigueData = new FatigueData();
      this.fatigueTimesData = new FatigueTimesData();
      this.manualReminderData = new ManualReminderData();
      this.milesTimelengthData = new MilesTimelengthData();
      this.overSpeedTimesData = new OverSpeedTimesData();
      this.percentageMileageData = new PercentageMileageData();
      this.percentageRoadData = new PercentageRoadData();
      this.percentageOverSpeedData = new PercentageOverSpeedData();
      // 微信绑定数据
      const currentMonthData = r[this.currentDate.getMonth()];
      this.wxBindingData.bind_captain_count = currentMonthData.bind_captain_count;
      this.wxBindingData.should_bind_captain_count = currentMonthData.should_bind_captain_count;
      this.wxBindingData.captain_bind_rate = currentMonthData.should_bind_captain_count
        ? Math.round(currentMonthData.bind_captain_count / currentMonthData.should_bind_captain_count * 1000) / 10
        : 0;
      this.wxBindingData.bind_colonel_count = currentMonthData.bind_colonel_count;
      this.wxBindingData.should_bind_colonel_count = currentMonthData.should_bind_colonel_count;
      this.wxBindingData.colonel_bind_rate = currentMonthData.should_bind_colonel_count
        ? Math.round(currentMonthData.bind_colonel_count / currentMonthData.should_bind_colonel_count * 1000) / 10
        : 0;
      const currentMonth = this.currentDate.getMonth();
      const splitNum = 11 - currentMonth;
      r.forEach((val, index) => {
        const position = index <= currentMonth ? splitNum + index : index - currentMonth - 1;
        // 托管车辆上线情况
        this.vehicleOnlineData.currnetNumber.push(val.trusteeship_count);
        this.vehicleOnlineData.lastNumber.push(val.pre_trusteeship_count);
        this.vehicleOnlineData.currnetRate.push(val.online_rate);
        this.vehicleOnlineData.lastRate.push(val.pre_online_rate);
        // 车均疲劳时长、疲劳时长占比
        this.fatigueData.fatigue_timelength_hour_avg.push(val.fatigue_timelength_hour_avg);
        this.fatigueData.org_fatigue_timelength_rate_avg.push(val.org_fatigue_timelength_rate_avg);
        this.fatigueData.pre_fatigue_timelength_hour_avg.push(val.pre_fatigue_timelength_hour_avg);
        this.fatigueData.pre_org_fatigue_timelength_rate_avg.push(val.pre_org_fatigue_timelength_rate_avg);
        this.fatigueData.country_org_fatigue_timelength_rate_avg.push(val.country_org_fatigue_timelength_rate_avg);
        // 车均疲劳次数
        this.fatigueTimesData.fatigue_count_avg.push(val.fatigue_count_avg);
        this.fatigueTimesData.pre_fatigue_count_avg.push(val.pre_fatigue_count_avg);
        this.fatigueTimesData.country_fatigue_count_avg.push(val.country_fatigue_count_avg);
        // 高风险车辆人工干预
        this.manualReminderData.message_count.push(val.message_count);
        this.manualReminderData.phone_count.push(val.phone_count);
        this.manualReminderData.pre_message_count.push(val.pre_message_count);
        this.manualReminderData.pre_phone_count.push(val.pre_phone_count);
        // 车均行驶里程、行驶时长
        this.milesTimelengthData.driving_hour_avg.push(val.driving_hour_avg ? Math.round(val.driving_hour_avg * 100) / 100 : val.driving_hour_avg);
        this.milesTimelengthData.pre_driving_hour_avg.push(val.pre_driving_hour_avg ? Math.round(val.pre_driving_hour_avg * 100) / 100 : val.pre_driving_hour_avg);
        this.milesTimelengthData.driving_km_avg.push(val.driving_km_avg);
        this.milesTimelengthData.pre_driving_km_avg.push(val.pre_driving_km_avg);
        // 百公里超速次数
        this.overSpeedTimesData.hundred_km_overspeed_count_avg.push(val.org_hundred_km_overspeed_count_avg);
        this.overSpeedTimesData.pre_hundred_km_overspeed_count_avg.push(val.pre_org_hundred_km_overspeed_count_avg);
        this.overSpeedTimesData.country_hundred_km_overspeed_count_avg.push(val.country_hundred_km_overspeed_count_avg);
        // 分时段里程占比
        this.percentageMileageData.pre_night_km_rate[position] = Math.round(index <= currentMonth ? val.night_km_rate : val.pre_night_km_rate);
        this.percentageMileageData.pre_evening_km_rate[position] = Math.round(index <= currentMonth ? val.evening_km_rate : val.pre_evening_km_rate);
        this.percentageMileageData.pre_afternoon_km_rate[position] = Math.round(index <= currentMonth ? val.afternoon_km_rate : val.pre_afternoon_km_rate);
        this.percentageMileageData.pre_day_km_rate[position] = Math.round(index <= currentMonth ? val.day_km_rate : val.pre_day_km_rate);
        this.percentageMileageData.pre_dawn_km_rate[position] = Math.round(index <= currentMonth ? val.dawn_km_rate : val.pre_dawn_km_rate);
        // this.percentageMileageData.pre_night_km_rate.push(index < splitNum ? val.pre_night_km_rate : val.night_km_rate);
        // this.percentageMileageData.pre_evening_km_rate.push(index < splitNum ? val.pre_evening_km_rate : val.evening_km_rate);
        // this.percentageMileageData.pre_afternoon_km_rate.push(index < splitNum ? val.pre_afternoon_km_rate : val.afternoon_km_rate);
        // this.percentageMileageData.pre_day_km_rate.push(index < splitNum ? val.pre_day_km_rate : val.day_km_rate);
        // this.percentageMileageData.pre_dawn_km_rate.push(index < splitNum ? val.pre_dawn_km_rate : val.dawn_km_rate);
        // 车均超速里程、超速里程占比
        this.percentageOverSpeedData.driving_km_overspeed_avg.push(val.driving_km_overspeed_avg);
        this.percentageOverSpeedData.driving_km_overspeed_avg_rate.push(val.driving_km_overspeed_avg_rate);
        this.percentageOverSpeedData.pre_driving_km_overspeed_avg.push(val.pre_driving_km_overspeed_avg);
        this.percentageOverSpeedData.pre_driving_km_overspeed_avg_rate.push(val.pre_driving_km_overspeed_avg_rate);
        this.percentageOverSpeedData.country_driving_km_overspeed_avg_rate.push(val.country_driving_km_overspeed_avg_rate);
        // 道路结构里程占比
        this.percentageRoadData.pre_highway_km_rate[position] = Math.round(index <= currentMonth ? val.highway_km_rate : val.pre_highway_km_rate);
        this.percentageRoadData.pre_nationalway_km_rate[position] = Math.round(index <= currentMonth ? val.nationalway_km_rate : val.pre_nationalway_km_rate);
        this.percentageRoadData.pre_provinceway_km_rate[position] = Math.round(index <= currentMonth ? val.provinceway_km_rate : val.pre_provinceway_km_rate);
        this.percentageRoadData.pre_otherway_km_rate[position] = Math.round(index <= currentMonth ? val.otherway_km_rate : val.pre_otherway_km_rate);
        // this.percentageRoadData.pre_highway_km_rate.push(index < splitNum ? val.pre_highway_km_rate : val.highway_km_rate);
        // this.percentageRoadData.pre_nationalway_km_rate.push(index < splitNum ? val.pre_nationalway_km_rate : val.nationalway_km_rate);
        // this.percentageRoadData.pre_provinceway_km_rate.push(index < splitNum ? val.pre_provinceway_km_rate : val.provinceway_km_rate);
        // this.percentageRoadData.pre_otherway_km_rate.push(index < splitNum ? val.pre_otherway_km_rate : val.otherway_km_rate);
      });
    }, e => {
      this.showErrorMessage(this.error.getErrorText(e));
    });
  }
  getCompanyRank() {
    const month = this.currentDate.getMonth() + 1;
    const option = {
      org_id: this.currentId,
      month: this.currentDate.getFullYear() + '-' + (month < 10 ? ('0' + month) : month)
    };
    this.api.getRank(option).pipe(
      finalize(() => {
        // this.hideLoading();
        if (typeof window.callPhantom === 'function') {
          this.isPdfMode = true;
          this.api.ChangeSize.next(true);
          // 延迟1秒等待图片渲染完成再通知打印服务
          setTimeout(() => window.callPhantom({ finish: true }), 1000);
        } else {
          setTimeout(() => {
            this.api.ChangeSize.next();
          }, 1000);
        }
      })
    ).subscribe(r => {
      this.rank = r;
      // setTimeout(() => {this.isPdfMode = true; this.api.ChangeSize.next(true); }, 3000)
    }, e => {
      this.showErrorMessage(this.error.getErrorText(e));
    });
  }
  /**
   * 在线导出
   */
  sendPdf() {
    const option: PdfResult = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      pageCount: this.hasComment() ? 18 : 17,
      singlePageWidth: 1280,
      singlePageHeight: 896,
      ignoreCache: !environment.production,
      upload: environment.production,
      entities: [{
        name: this.currentInsurance,
        // tslint:disable-next-line:max-line-length
        // webPageUrl: 'https://insurancetest.sjfkpt.com/insurance-report/195/2020-11/%E5%B9%B3%E5%AE%89%E6%96%B0%E7%96%86%E5%88%86%E5%85%AC%E5%8F%B8; '// location.href
        webPageUrl: location.href
      }],
    };
    if (!environment.production) {
      console.log('开发环境')
    } else {
      console.log('生产环境')
    }
    // this.texts = 'pdf导出中,请稍等。';
    // this.showLoading();
    // this.showLoading('开始导出');
    // const month = option.month < 10 ? '0' + option.month : option.month + '';
    const reqToExport = () => {
      this.api.sendPdf(option).subscribe(
        r => {
          // this.hideLoading();
          saveAs(r, `${moment(this.currentDate).format('YYYY年M月')}${this.currentInsurance}机构报告.pdf`);
        }, e => {
          // this.hideLoading();
          this.showErrorMessage('导出失败，请检查网络后重试。');
        });
    };
    reqToExport();
  }

  showLoading() {
    document.getElementById('loading2').style.display = 'block';
  }

  hideLoading() {
    document.getElementById('loading2').style.display = 'none';
  }
}
