import { checkBoxOption } from './my-check-box.model';
import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
@Component({
  selector: 'app-my-check-box',
  templateUrl: './my-check-box.component.html',
  styleUrls: ['./my-check-box.component.css']
})
export class MyCheckBoxComponent implements OnInit {
  // 伸缩中
  playing: boolean;
  // 显示下拉
  hide = false;
  // 首项下圆角
  bottomCircle = true;
  // selectObj:SelectOption = new SelectOption("","");
  // 指定显示字段参数名
   @Input() key = 'key';
  // 指定值字段参数名
  @Input() value = 'value';
  // 按钮宽度
  @Input() buttonWidth = '300px';
  // 显示栏宽度
  @Input() boardWidth = '300px';
  // 按钮文字
  @Input() myPlaceholder = '请选择';
  // 是否圆角
  @Input() ifCircle = false;
  // 全部参数
  @Input() selectOptionList: checkBoxOption<any>[] = [];
  // 已选中的参数
  @Input() selectedOptionList: checkBoxOption<any>[] = [];
  // 输出
  @Output() sendList = new EventEmitter();
  // 当前是否全选
  all = true;
  @HostListener('window:click'/*, ['$event']*/)

  onClick() {
    if (this.hide) {
      this.finish();
    }
    this.hide = false;
  }

  constructor(  ) {  }

  ngOnInit(  ) {

  }

  clickButton() {
    if (!this.hide) {
      this.reSet();
    } else {
      this.finish();
    }
  }

  reSet() {
    this.all = false;
    let notAll = false;
/*    if(this.selectOptionList.length>0){
      let nameList = []
      for(let name in this.selectOptionList[0]){
        nameList.push(name)
      }
      if(nameList.length>0){
        this.key = nameList[0]
      }
    }*/
    this.selectOptionList.forEach(r => {
      r.myCheckBox_isSelected = false;
      if (this.selectedOptionList.length != 0) {
        if (this.selectedOptionList.find(a => a[this.key] === r[this.key])) {
          r.myCheckBox_isSelected = true;
          if (!notAll) {
            this.all = true;
          }
        } else {
          notAll = true;
          this.all = false;
        }
      }
    });
  }

  finish() {
    const outPutList = [];
    this.selectOptionList.forEach(r => {
      if (r.myCheckBox_isSelected) {
        delete r.myCheckBox_isSelected;
        outPutList.push(r);
      }
    });
    this.sendList.emit(outPutList);
  }

  setAll() {
    this.selectOptionList.forEach(r => {
      if (!this.all) {
        r.myCheckBox_isSelected = false;
      } else {
        r.myCheckBox_isSelected = true;
      }
    });
  }

  notAll(val) {
    if (!val) {
      this.all = false;
    }
  }
}
