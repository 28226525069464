/**
 * 速度折线图
 */
import { Component, OnInit, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { TrajectoryWithAlarm } from '../../core/vehicle-trajectory.model';


@Component({
  selector: 'app-speed-line',
  templateUrl: './speed-line.component.html',
  styleUrls: ['./speed-line.component.css']
})
export class SpeedLineComponent implements OnInit {
  data: TrajectoryWithAlarm[] = [];
  @ViewChild('svg', { static: true }) svg: ElementRef;
  // 画布宽度
  svgWidth = 11;
  // 画布高度
  svgHeight: number;
  // 折线图配置
  speedLineString = '';
  // 当前点
  _currentPoint = 0;
  @Input()
  set currentPoint(num: number) {
    this.svgWidth = this.svg.nativeElement.clientWidth - 10;
    this._currentPoint = num;
    this.setLine(this.data);
  }
  get currentPoint(): number {
    return this._currentPoint;
  }
  // 当前速度
  @Input() currentSpeed = 0;
  // 速度与高度之间的比例
  speedRate = 0.85;
  // 总点数
  total = 1;
  // 最大速度
  max = 100;

  @HostListener('window:resize'/*, ['$event']*/)
  onResize() {
    this.setLine(this.data);
  }

  constructor() {

  }

  ngOnInit() {

  }

  setLine(query: TrajectoryWithAlarm[]) {
    this.svgWidth = this.svg.nativeElement.clientWidth - 10;
    this.svgHeight = this.svg.nativeElement.clientHeight - 10;
    this.max = Math.max.apply(Math, query.map(e => e.speed)) + 20;
    if (this.max < 100) {
      this.max = 100;
    }
    this.speedRate = this.svgHeight / this.max;
    this.total = query.length - 1;
    this.data = query;
    if (this.total > 1) {
      this.speedLineString = '';
      query.forEach((e, i) => {
        const x = i / this.total * (this.svgWidth - 10) + 5;
        const y = this.svgHeight - e.speed * this.speedRate;
        if (this.speedLineString) {
          this.speedLineString = this.speedLineString + ` ${x},${y}`;
        } else {
          this.speedLineString = `${x},${y}`;
        }
      });
    } else {
      this.total = 1;
      this.speedLineString = '';
    }
  }
}
