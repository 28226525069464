import { HttpParams, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';

/**
 * 所有Http请求的基类
 */
export abstract class BaseRequest {

  /**
   * url基地址
   */
  baseUrl: string;

  /**
   * json中status
   */
  jsonStatus = 'unknown';

  /**
   * 最外层status是否为ok
   */
  isResponseOk = false;

  /**
   * 是否成功返回并成功处理了逻辑
   */
  isSuccess = false;

  /**
   * 超时时间，默认10秒
   */
  timeout = 30000;

  /**
   * result_status值
   */
  resultStatus: string;

  /**
   * 重试次数
   */
  retryTimes = 1;

  /**
   * 发生错误时候的错误信息
   */
  errorInfo: any[] = [];

  protected _responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json';

  protected _withCredentials = true;

  /**
   * 请求方式，默认post
   */
  protected method = 'POST';

  get responseType(): 'arraybuffer' | 'blob' | 'json' | 'text' {
    return this._responseType;
  }

  get needProgress(): boolean {
    return false;
  }

  get withCredentials(): boolean {
    return this._withCredentials;
  }

  /**
   * 创建请求
   */
  makeRequest(): HttpRequest<any> {
    const params = this.makeParams();
    let httpParams: HttpParams;
    if (params) {
      httpParams = new HttpParams({ fromObject: params });
    }
    return new HttpRequest(
      this.method,
      this.makeRequestUrl(),
      this.makeRequestBody(),
      {
        params: httpParams,
        headers: this.makeHeaders(),
        responseType: this.responseType,
        reportProgress: false,
        withCredentials: this.withCredentials
      }
    );
  }

  /**
   * @return 是否解析数据成功
   */
  dealResponse(response: HttpResponse<any>): boolean {
    /*console.log(`Response headers: ${JSON.stringify(response.headers)}, body:${response.text()}`);*/
    const contentType = response.headers.get('Content-Type');
    if (contentType.indexOf('application/json') >= 0) {
      const json = response.body;
      this.isSuccess = this.dealJsonResponse(json);
    }
    return this.isSuccess;
  }

  /**
   * @return 基地址
   */
  protected getBaseUrl(): string {
      return this.baseUrl;
  }

  /**
   * @return 每个协议的地址部分
   */
  protected getParameterUrl(): string {
      return '';
  }

  /**
   * @return 合成整个请求的url
   */
  protected makeRequestUrl(): string {
      return this.getBaseUrl() + this.getParameterUrl();
  }

  protected makeParams(): { [param: string]: string | string[] } {
    return null;
  }

  /**
   * 创建请求头部分
   */
  protected makeHeaders(): HttpHeaders {
    const headers: { [name: string]: string } = {
      'Content-Type': 'application/json'
    };
    const extra = this.makeExtraHeaders();
    if (extra) {
      for (const key in extra) {
        if (extra[key]) {
          headers[key] = extra[key];
        }
      }
    }
    return new HttpHeaders(headers);
  }

  protected makeExtraHeaders(): { [name: string]: string } {
    return null;
  }

  /**
   * 创建请求体部分
   */
  protected makeRequestBody(): any {
      return null;
  }

  /**
   * 处理返回的json中的数据部分（json为包裹在"result"中的内容）
   * @return 是否处理成功
   */
  protected dealJsonResult(json: any): boolean {
      return true;
  }

  /**
   * 处理请求返回的json数据
   */
  protected dealJsonResponse(json: any): boolean {
    this.jsonStatus = json['status'];
    if (this.jsonStatus === 'ok') {
      this.isResponseOk = true;
      const result = json['result'];
      this.resultStatus = result['result_status'];
      if (result['errorInfo']) {
        for (const obj of result['errorInfo']) {
          this.errorInfo.push(obj);
        }
      }
      if (this.resultStatus.toLocaleLowerCase() !== 'ok') {
        throw new Error(result['result_status']);
      }
      return this.dealJsonResult(result);
    } else {
      throw new Error('server_error');
    }
/*    return this.isResponseOk;*/
  }
}
