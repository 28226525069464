import { IndexComponent } from './index.component';
/**
 * Created by AGUANG on 2017/2/15.
 */
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NoPressionPageComponent } from '../shared/no-pression-page/no-pression-page.component';
import { AuthGuardService } from '../core/service/auth/auth-guard.service';


@NgModule({
  imports: [
    RouterModule.forChild([
      // {
      //   path: '',
      //   redirectTo: '/index',
      //   pathMatch: 'full'
      // },
      {
        path: 'index',
        component: IndexComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'empty',
        component: NoPressionPageComponent,
        canActivate: [AuthGuardService]
      }
    ])
  ],
  exports: [RouterModule]
})
export class IndexRoutingModule { }
