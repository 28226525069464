import { AppBaseRequest } from './app.protocol';
// import { User, ReportStatus } from '../model/user.model';
import { Organization } from '../model/permission.model';
import { WeChat } from '../model/main.model';
import { InsuranceUser, User } from '../model/user.model';


/**
 *  获取保险平台登录二维码token
 */
export class ReqInSuranceLoginToken extends AppBaseRequest {

  token: string;

  constructor() {
    super();
  }

  protected getParameterUrl(): string {
    return 'login/getToken';
  }


  protected makeRequestBody(): any {
    return {};
  }

  protected dealJsonResult(json: any): boolean {
    this.token = json['token'];
    return true;
  }
}

/**
 *  获取运输登录二维码token
 */
export class ReqTransportLoginToken extends AppBaseRequest {

  token: string;

  constructor() {
    super();
  }

  protected getParameterUrl(): string {
    return 'wx/enterprise/user/login/getToken';
  }

  protected makeRequestBody(): any {
    return {};
  }

  protected dealJsonResult(json: any): boolean {
    this.token = json['token'];
    return true;
  }
}

/**
 *  二维码保险平台登录
 */
export class ReqInsuranceQrcodeLogin extends AppBaseRequest {
  get user(): User {
    return this._user;
  }

  // get user(): InsuranceUser {
  //   return this._user;
  // }

  get session(): string {
    return this._session;
  }

  get expiration(): Date {
    return this._expire;
  }

  // private _session: string;
  // private _user: User;
  // private _expire: Date;
  private _session: string;
  private _user: User;
  private _expire: Date;


  constructor(private token: string) {
    super();
  }

  protected makeRequestBody(): any {
    return {
      token: this.token
    };
  }

  protected getParameterUrl(): string {
    return 'login/tokenLogin';
  }


  protected dealJsonResult(json: any): boolean {
    this._session = json['session'];
    this._user = new User().parse(json['user']);
    // this._user = new InsuranceUser();
    this._expire = new Date(json['expiration_date']);
    return true;
  }
}

/**
 *  二维码登录
 */
// export class ReqTransportQrcodeLogin extends AppBaseRequest {
//   get user(): User {
//     return this._user;
//   }

//   get session(): string {
//     return this._session;
//   }

//   get expiration(): Date {
//     return this._expire;
//   }

//   private _session: string;
//   private _user: User;
//   private _expire: Date;


//   constructor(private token: string) {
//     super();
//   }

//   protected makeRequestBody(): any {
//     return {
//       token: this.token
//     };
//   }

//   protected getParameterUrl(): string {
//     return 'wx/enterprise/user/login/tokenLogin';
//   }

//   protected dealJsonResult(json: any): boolean {
//     this._session = json['session'];
//     this._user = new User().parse(json['user']);
//     this._expire = new Date(json['expiration_date']);
//     return true;
//   }
// }


/**
 *  平台用户登录
 */
// export class ReqPlatformUserLogin extends AppBaseRequest {
//   get user(): User {
//     return this._user;
//   }

//   get session(): string {
//     return this._session;
//   }

//   get expiration(): Date {
//     return this._expire;
//   }

//   private _session: string;
//   private _user: User;
//   private _expire: Date;

//   constructor(private account: string, private password: string) {
//     super();
//   }

//   protected getParameterUrl(): string {
//     return 'customer/login';
//   }

//   protected makeRequestBody(): any {
//     return {
//       login_name: this.account,
//       password: this.password
//     };
//   }

//   protected dealJsonResult(json: any): boolean {
//     this._session = json['session'];
//     this._user = new User().parse(json['user']);
//     this._expire = new Date(json['expiration_date']);
//     return true;
//   }
// }

/**
 * 平台用户修改密码
 */
export class ReqEditPlatformUserPassword extends AppBaseRequest {

  constructor(private id: string, private oldPassword: string, private newPassword: string) {
    super();
  }

  protected getParameterUrl(): string {
    return 'customer/modifyPassword';
  }

  protected makeRequestBody(): any {
    return {
      user_id: this.id,
      old_password: this.oldPassword,
      new_password: this.newPassword
    };
  }
}

/**
 * 获取分公司列表
 */
export class ReqGetChildCompany extends AppBaseRequest {

  result: Organization[] = [];

  constructor() {
    super();
  }

  protected getParameterUrl(): string {
    return 'wx/label/searchDepositCompany';
  }

  protected makeRequestBody(): any {
    return {
    };
  }

  protected dealJsonResult(json: any): boolean {
    if (json['companys']) {
      for (const obj of json['companys']) {
        this.result.push(new Organization().parse(obj));
      }
    }
    return true;
  }
}

/**
 * 获取微信公众号列表
 */
export class ReqGetWeChatList extends AppBaseRequest {

  weChats: WeChat[] = [];

  constructor() {
    super();
  }

  protected makeRequestBody(): any {
    return {};
  }

  protected getParameterUrl(): string {
    return 'wx/account/list';
  }

  protected dealJsonResult(json: any): boolean {
    for (const obj of json['wx_accounts']) {
      this.weChats.push(new WeChat().parse(obj));
    }
    return true;
  }
}

/**
 * 获取月报生成情况
 */
// export class ReqGetReportStatus extends AppBaseRequest {

//   option: ReportStatus;

//   constructor(private id) {
//     super();
//   }

//   protected makeRequestBody(): any {
//     return {
//       company_id: this.id
//     };
//   }

//   protected getParameterUrl(): string {
//     return 'wx/month/statistics/checkReportStatusOfLastMonth';
//   }

//   protected dealJsonResult(json: any): boolean {
//     this.option = new ReportStatus().parse(json['data']);
//     return true;
//   }
// }
