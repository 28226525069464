import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { InsuranceUser } from 'src/app/core/model/user.model';
import { UserService } from 'src/app/core/service/user.service';
import { AuthService } from 'src/app/core/service/auth/auth.service';



@Component({
  moduleId: module.id,
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.css']
})
export class AsideComponent implements OnInit {

  currentUser: InsuranceUser;

  isMoreData = false;
  moreDataArr: string[] = ['/main/analysis-report', '/main/safety', '/main/rank', '/main/record'];
  authCodeTree: string[] = [];

  constructor(
    private router: Router,
    public userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute) {

    }
  ngOnInit() {
    let url = location.pathname;
    for(let i of this.moreDataArr){
      if(url.indexOf(i)>=0){
        this.isMoreData = true;
        break
      }else{
        this.isMoreData = false;
      }
    };
    this.router.events.subscribe(
      r => {
        if (r instanceof NavigationEnd) {
          for (const i of this.moreDataArr) {
            if (r.url.indexOf(i) >= 0) {
              this.isMoreData = true;
              break;
            } else {
              this.isMoreData = false;
            }
          }
        }
      }
    );
    this.userService.getUserInfo(this.authService.getLocalJWT()).subscribe(user => {
      if (user) {
        if (user.insurance_role_list && user.insurance_role_list.length > 0) {
          user.insurance_role_list.forEach(role => {
            if (role.insurance_authority_list) {
              this.getAuthTree(role.insurance_authority_list);
            }
          })
        }
        this.currentUser = user;
      }
    });
  }

  getAuthTree(auth) {
    auth.forEach(r => {
      if (this.authCodeTree.indexOf(r.code) < 0) {
        this.authCodeTree.push(
          r.code
        );
      }
      if (r.children_list && r.children_list.length > 0) {
        this.getAuthTree(r.children_list);
      }
    })
  }
  // 是否有权限  
  isHasAuthrity(code: string): boolean {
    return this.authCodeTree.indexOf(code) >= 0;
  }
}
