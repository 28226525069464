/**
 * Created by AGUANG on 2017/2/15.
 */
import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../core/service/user.service';
import {Subscription} from 'rxjs';
import {BasePage} from '../core/base-page/base-page';
import {HttpService} from '../core/network/http.service';

/**
 * This class represents the main application component.
 */
@Component({
  moduleId: module.id,
  templateUrl: 'public.component.html',
})
export class PublicComponent extends BasePage implements OnInit, AfterViewInit {

  constructor(private router: Router, private userService: UserService, private httpService: HttpService) {
    super();
  }

  // 显示提示信息框
  showDialog = false;

  // 错误信息
  errorMessage: string;

  showChangePassword = false;

  private _onResultStatusSubscription: Subscription;

  // 显示错误信息
  showErrorMessage(message: string) {
    this.errorMessage = message;
    this.showDialog = true;
  }

  ngAfterViewInit() {

    // 因为登陆后的app界面都从本component走，只有明确导航到''或者'/'的时候，才默认跳到主界面
    // 否则不做强制跳转，避免在其他界面刷新仍然跳转到主界面
    if (this.router.url === '/') {
      this.router.navigate(['']);
    }
  }

  ngOnInit() {
    this._onResultStatusSubscription = this.httpService.onResultStatus.subscribe(status => this.onResultStatus(status));
  }

  private onResultStatus(status: string) {
    // if (status === 'login_expired') {
    //   this.userService.logout().subscribe(r => this.router.navigate(['/app-insurance-login']));
    // }
  }

  fresh() {

  }


}
