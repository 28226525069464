import { Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {
  nameList = [];
  @Input('pageParams') pageParams;
  showLabel = true;
  @Output() changeCurPage: EventEmitter<Number> = new EventEmitter;
  @Input('text') text;
  _totalPage = 10;
  constructor() {
    const self = this;
  }
  // 跳转
  jump(num, pageParams) {
    if (num > pageParams.totalPage) {
      num = pageParams.totalPage;
    } else if (num <= 0) {
      num = 1;
    }
    this.changeCurPage.emit(num);
  }
  getPageList(pageParams) {
    pageParams = eval('pageParams');
    const pageList = [
    ];
    if (pageParams.curPage <= pageParams.totalPage) {
      pageList.push({
        pageNo: pageParams.curPage,
      });
    } else {
      if (pageParams.curPage > 5) {
        pageList.push({
          pageNo: pageParams.curPage + 1,
        });
      }
    }
    return pageList;
  }

  ngOnInit() {
  }
  changePage(pageNo) {
    const self = this;
    self.pageParams.curPage = pageNo;  // 当前页码
    self.changeCurPage.emit(self.pageParams.curPage);
  }
}
