import { Component, ElementRef, HostListener, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import * as echarts from 'echarts';
export class ManualReminderData {
  message_count: number[] = [];
  phone_count: number[] = [];
  pre_message_count: number[] = [];
  pre_phone_count: number[] = [];
}
@Component({
  selector: 'app-manual-reminder',
  templateUrl: './manual-reminder.component.html',
  styleUrls: ['./manual-reminder.component.css']
})
export class ManualReminderComponent implements OnInit, AfterViewInit {
  title = '高风险车辆人工干预';
  @ViewChild('chart', { static: true }) _chart: ElementRef;
  chart: echarts.ECharts;
  countMsg = 0;
  countPhone = 0;
  onResize() {
    if (this.chart) { this.chart.resize(); }
  }
  // tslint:disable-next-line:member-ordering
  @Input() date = new Date();
  // tslint:disable-next-line:member-ordering
  _data: ManualReminderData = new ManualReminderData;
  @Input()
  set data(data: ManualReminderData) {
    this._data = data;
    if (data) {
      this.setChart(data);
      this.countMsg = 0;
      data.message_count.forEach(r => {
        if (r) {
          this.countMsg += r;
        }
      });
      this.countPhone = 0;
      data.phone_count.forEach(r => {
        if (r) {
          this.countPhone += r;
        }
      });
    }
  }
  get data(): ManualReminderData {
    return this._data;
  }
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.chart = echarts.init(this._chart.nativeElement);
  }
  setChart(data: ManualReminderData) {
    const month = this.date.getMonth();
    const current = [], last = [];
    for (let i = 0; i <= month; i++) {
      current.push(`${this.date.getFullYear()}-${i + 1}`);
    }
    for (let i = 0; i < 11 - month; i++) {
      last.push(`${this.date.getFullYear() - 1}-${month + i + 2}`);
    }
    this.chart.resize();
    // const option = {
    //   legend: {
    //     data: ['短信次数', '电话次数']
    //   },
    //   tooltip: {
    //     trigger: 'axis',
    //     axisPointer: {
    //       type: 'cross',
    //       crossStyle: {
    //         color: '#999'
    //       }
    //     }
    //   },
    //   grid: {
    //     left: '15px',
    //     right: '15px',
    //     bottom: '15px',
    //     top: '40px',
    //     containLabel: true
    //   },
    //   xAxis: [
    //     {
    //       type: 'category',
    //       data: last.concat(current),
    //       axisPointer: {
    //         type: 'shadow'
    //       }
    //     }
    //   ],
    //   yAxis: [
    //     {
    //       type: 'value',
    //       axisLabel: {
    //         formatter: '{value}'
    //       },
    //       axisLine: {
    //         show: false
    //       },
    //       axisTick: {
    //         show: false
    //       },
    //       splitLine: {
    //         show: true,
    //         lineStyle: {
    //           type: 'dashed'
    //         }
    //       },
    //     },
    //     {
    //       type: 'value',
    //       min: 0,
    //       axisLabel: {
    //         formatter: '{value}'
    //       },
    //       axisLine: {
    //         show: false
    //       },
    //       axisTick: {
    //         show: false
    //       },
    //       splitLine: {
    //         show: true,
    //         lineStyle: {
    //           type: 'dashed'
    //         }
    //       }
    //     }
    //   ],
    //   series: [
    //     {
    //       name: '短信次数',
    //       type: 'bar',
    //       itemStyle: {
    //         color: {
    //           type: 'linear',
    //           x: 0,
    //           y: 0,
    //           x2: 0,
    //           y2: 1,
    //           colorStops: [{
    //             offset: 0, color: '#27BBFF' // 0% 处的颜色
    //           }, {
    //             offset: 0.5, color: '#6361FF' // 100% 处的颜色
    //           }],
    //           global: false // 缺省为 false
    //         }
    //       },
    //       label: {
    //         show: true,
    //         fontWeight: 'bold',
    //         position: 'top'
    //       },
    //       data: data.message_count
    //     },
    //     {
    //       name: '电话次数',
    //       type: 'line',
    //       symbol: 'circle',
    //       itemStyle: {
    //         color: '#B620E0'
    //       },
    //       yAxisIndex: 1,
    //       label: {
    //         show: true,
    //         fontWeight: 'bold',
    //         position: 'top'
    //       },
    //       data: this.data.phone_count
    //     }
    //   ]
    // };
    const option = {
      legend: {
        data: [`${this.date.getFullYear() - 1}年短信次数`, `${this.date.getFullYear()}年短信次数`,
        `${this.date.getFullYear() - 1}年电话次数`, `${this.date.getFullYear()}年电话次数`]
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '15px',
        right: '15px',
        bottom: '15px',
        top: '40px',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '单位(次)',
          axisLabel: {
            formatter: '{value}'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
        },
        {
          type: 'value',
          name: '单位(次)',
          axisLabel: {
            formatter: '{value}'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          splitNumber: 5,
        }
      ],
      series: [
        {
          name: `${this.date.getFullYear() - 1}年短信次数`,
          type: 'bar',
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#27BBFF' // 0% 处的颜色
              }, {
                offset: 0.5, color: '#6361FF' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          label: {
            show: true,
            position: 'top'
          },
          data: data.pre_message_count
        },
        {
          name: `${this.date.getFullYear()}年短信次数`,
          type: 'bar',
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#FFA327' // 0% 处的颜色
              }, {
                offset: 0.5, color: '#FF4D28' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          label: {
            show: true,
            position: 'top'
          },
          data: data.message_count
        },
        {
          name: `${this.date.getFullYear() - 1}年电话次数`,
          type: 'line',
          symbol: 'circle',
          itemStyle: {
            color: '#32C5FF'
          },
          yAxisIndex: 1,
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.pre_phone_count
        },
        {
          name: `${this.date.getFullYear()}年电话次数`,
          type: 'line',
          symbol: 'circle',
          itemStyle: {
            color: '#B620E0'
          },
          yAxisIndex: 1,
          label: {
            show: true,
            fontWeight: 'bold',
            position: 'top'
          },
          data: data.phone_count
        }
      ]
    };
    this.chart.setOption(option);
  }
}

