import { Directive, ElementRef, HostListener, Renderer, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[autoHeightSet]'
})
export class AutoHeightSetDirective implements OnInit {

  @Input('autoHeightSet') value: number;

  @Input('fixedHeight') valueFix: number;

  constructor(private el: ElementRef, private renderer: Renderer) {}

  @HostListener('window:resize'/*, ['$event']*/)
  onResize() {
    if (this.valueFix) {
      this.autoHeightFixSet();
    } else {
      this.autoHeightSet();
    }
  }

  ngOnInit() {
    if (this.valueFix) {
      this.autoHeightFixSet();
    } else {
      this.autoHeightSet();
    }
  }

  private autoHeightSet() {
    this.renderer.setElementStyle(this.el.nativeElement, 'min-height', document.body.clientHeight - this.value + 'px');
  }

  private autoHeightFixSet() {
    this.renderer.setElementStyle(this.el.nativeElement, 'height', document.body.clientHeight - this.valueFix + 'px');
  }
}
