import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  @Input() text =  '';

  @Input() showPop = false;

  @Output() hide = new EventEmitter();

  @Input() callBack: Function;

  constructor() { }

  ngOnInit() {
  }

  accept() {
    if (this.callBack) {
      this.callBack();
    }
    this.hide.emit(false);
  }

}
