import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdasPorjectRoutingModule } from './adas-porject-routing.module';
import { AdasPorjectComponent } from './adas-porject.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AdasService } from './adas.service';
import { UserService } from '../core/service/user.service';

@NgModule({
  imports: [
    CommonModule,
    AdasPorjectRoutingModule,
    FormsModule,
    SharedModule
  ],
  providers: [AdasService, UserService],
  declarations: [AdasPorjectComponent]
})
export class AdasPorjectModule { }
